import React, { FunctionComponent, SVGProps } from "react";

import {
  RealTimeIcon, ArchiveIcon, RequestsIcon, ScheduleIcon, CartBlackIcon
} from "../assets";

import {
  Archive,
  LoginAdministrative,
  Login,
  LoginUser,
  ManualPurchase,
  RealTime,
  Request,
  ConfirmManualPurchase,
  UpdatePassword,
  ProductVariablePricePurchase,
  VariablePricePurchase,
  VisitorPurchase,
  VisitorConfirmPurchase
} from "../pages";

import { RoleType } from "../types";
import { ScheduledOrder } from "../pages/ScheduledOrder";
import { ConfirmIndividualPurchase } from "../pages/ConfirmIndividualPurchase";
import { IndividualPurchase } from "../pages/IndividualPurchase";

export type Path =
  | "/"
  | "/real-time"
  | "/archive"
  | "/requests"
  | "/login-user"
  | "/login-admin"
  | "/manual-purchase"
  | "/confirm-manual-purchase"
  | "/individual-purchase"
  | "/confirm-individual-purchase"
  | "/update-password"
  | "/variable-price-purchase"
  | "/product-variable-price-purchase"
  | "/visitor-purchase"
  | "/visitor-confirm-purchase"
  | "/scheduled-order";

export interface BaseRouteInterface {
  id: number;
  path: Path;
  exact?: boolean;
  Page: React.FunctionComponent;
  label: string;
  subTitle?: string;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export interface RedirectRouteInterface extends BaseRouteInterface {
  redirect: true;
  to: Path;
}

export interface NoRedirectRouteInterface extends BaseRouteInterface {
  redirect?: false;
  to?: never;
}

export type RouteInterface = RedirectRouteInterface | NoRedirectRouteInterface;

const DefaultPage: React.FC = () => <></>;

export const publicRoutes: RouteInterface[] = [
  {
    id: 1,
    path: "/",
    Page: Login,
    label: "",
  },
];

export const kitchenRoutes: RouteInterface[] = [
  {
    id: 1,
    path: "/",
    exact: true,
    redirect: true,
    to: "/real-time",
    Page: DefaultPage,
    label: "",
  },

  {
    id: 2,
    path: "/real-time",
    exact: true,
    Page: RealTime,
    label: "Tempo Real",
    Icon: RealTimeIcon,
  },

  {
    id: 3,
    path: "/archive",
    exact: true,
    Page: Archive,
    label: "Arquivos",
    Icon: ArchiveIcon,
  },

  {
    id: 4,
    path: "/requests",
    exact: true,
    Page: Request,
    label: "Pedidos",
    Icon: RequestsIcon,
  },

  {
    id: 4,
    path: "/login-admin",
    exact: true,
    Page: LoginAdministrative,
    label: "",
  },
  {
    id: 5,
    path: "/variable-price-purchase",
    exact: true,
    Page: VariablePricePurchase,
    label: "Venda de produtos com preço variável",
    subTitle: "Selecione um produto para realizar a venda com preço variável",
    Icon: CartBlackIcon,
  },
  {
    id: 5,
    path: "/product-variable-price-purchase",
    exact: true,
    Page: ProductVariablePricePurchase,
    label: "",
  },
  {
    id: 5,
    path: "/confirm-manual-purchase",
    exact: true,
    Page: ConfirmManualPurchase,
    label: "",
  },
  {
    id: 6,
    path: "/scheduled-order",
    exact: true,
    Page: ScheduledOrder,
    label: "Pedidos Agendados",
    Icon: ScheduleIcon,
  },

];

export const totemRoutes: RouteInterface[] = [
  {
    id: 1,
    path: "/",
    exact: true,
    redirect: true,
    to: "/login-user",
    Page: DefaultPage,
    label: "",
  },

  {
    id: 2,
    path: "/login-user",
    exact: true,
    Page: LoginUser,
    label: "Login Usuário",
  },

  {
    id: 3,
    path: "/login-admin",
    exact: true,
    Page: LoginAdministrative,
    label: "",
  },

  {
    id: 4,
    path: "/manual-purchase",
    exact: true,
    Page: ManualPurchase,
    label: "Compra Manual",
  },

  {
    id: 5,
    path: "/confirm-manual-purchase",
    exact: true,
    Page: ConfirmManualPurchase,
    label: "Confirmar Compra Manual",
  },

  {
    id: 6,
    path: "/update-password",
    exact: true,
    Page: UpdatePassword,
    label: "",
  },

  {
    id: 7,
    path: "/visitor-purchase",
    exact: true,
    Page: VisitorPurchase,
    label: "Compra Visitante",
  },

  {
    id: 8,
    path: "/visitor-confirm-purchase",
    exact: true,
    Page: VisitorConfirmPurchase,
    label: "",
  },

  {
    id: 9,
    path: "/individual-purchase",
    exact: true,
    Page: IndividualPurchase,
    label: "Compra Individual",
  },

  {
    id: 10,
    path: "/confirm-individual-purchase",
    exact: true,
    Page: ConfirmIndividualPurchase,
    label: "",
  }
];

export const privateRoutes: RouteInterface[] = [
  ...kitchenRoutes,
  ...totemRoutes,
];

export const getPrivateRoutes = (userType: RoleType): RouteInterface[] => {
  switch (userType) {
    case "kitchen":
      return kitchenRoutes;
    case "totem":
      return totemRoutes;
    default:
      return totemRoutes;
  }
};

export const getLabel = (path: Path): string =>
  privateRoutes.find((route: RouteInterface) => route.path === path)?.label ||
  "";

export const getSubTitle = (path: Path): string =>
  privateRoutes.find((route: RouteInterface) => route.path === path)?.subTitle ||
  "";

export const validPath = (path: Path): boolean =>
  path !== "/" &&
  privateRoutes.map((route: RouteInterface) => route.path).includes(path);

export const validPathByUserRole = (
  userType: RoleType | null,
  path: Path
): boolean => {
  const routes = userType ? getPrivateRoutes(userType) : publicRoutes;

  return routes
    .map((route: RouteInterface) => route.path)
    .includes(path as Path);
};
