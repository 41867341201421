import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { ProfileIcon } from "../../assets";

import { getFirebaseRealTimeDatabase } from "../../services/firebaseRealTimeDatabase";
import { getWalletByClient } from "../../services/totem";

import { useStore } from "../../store";
import { isWhatPercentOf } from "../../utils/isWhatPercentOf";
import {
  Progress,
  PurchaseView,
  Button,
  PaymentMethodModal,
  BlockedModal,
} from "../index";

import useStyles from "./styles";
import { formatCurrency } from "../../utils/format";

export const ClientVariablePrice: React.FC = () => {
  const {
    userManager,
    userTotem,
    order,
    navigation,
  } = useStore();

  const Styles = useStyles({ picture: userTotem.picture });

  const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState<boolean>(false);

  const [isFinalizePurchaseLoading, setIsFinalizePurchaseLoading] = useState(false);
  const [menuIsOpened, setMenuIsOpened] = useState(false);
  const [openBlockedModal, setOpenBlockedModal] = useState<boolean>(false);

  const loadWallet = async () => {
    if (userTotem.id) {
      const wallet = await getWalletByClient(userTotem.id);

      if (wallet) userTotem.updateWallet(wallet);
    }
  };

  const isBlockedProductLimit = (): boolean => (order.totalAmount / 100) > (userTotem.wallet.periodLimit - userTotem.wallet.spent) && !!userTotem.id;
  const Limit = formatCurrency(userTotem.wallet.periodLimit - userTotem.wallet.spent);

  const finalizePurchase = async () => {
    setIsFinalizePurchaseLoading(true);
    navigation.navigateTo("/confirm-manual-purchase", {
      state: {
        type: "variablePrice",
        typeVariablePrice: 'notManualCreditVariablePrice',
      }
    });
  };

  const handleFinalizePurchase = () => {
    if (isBlockedProductLimit()) {
      setOpenBlockedModal(true);
    } else if (userTotem.id) finalizePurchase();
    else setOpenPaymentMethodModal(true);
  };

  useEffect(() => {
    getFirebaseRealTimeDatabase(userManager.entity, loadWallet);
  }, []);

  return (
    <div className={Styles.clientView}>
      <div className={Styles.client}>
        <div className={Styles.clientInfo}>
          <Typography component="span">{userTotem.name || 'Gerente'}</Typography>

          <Progress
            percentage={isWhatPercentOf(
              userTotem.wallet?.periodLimit,
              userTotem.wallet?.spent
            ) || 0}
          />
        </div>

        {
          userTotem.picture ? (
            <div className={Styles.clientPhoto} onClick={() => setMenuIsOpened(!menuIsOpened)} />
          ) : (
            <div className={Styles.picture} onClick={() => setMenuIsOpened(!menuIsOpened)}>
              <ProfileIcon />
            </div>
          )
        }
      </div>

      <PurchaseView totem={userTotem} order={order} />

      <div className={Styles.footer}>
        <Button
          color="primary"
          className={Styles.button}
          disabled={order.items.length === 0 || isFinalizePurchaseLoading}
          onClick={handleFinalizePurchase}
        >
          Finalizar Compra
        </Button>
      </div>

      <BlockedModal
        message={`Você está tentando realizar uma compra de: ${formatCurrency(order.totalAmount / 100)}, mas seu saldo disponível do limite é: ${Limit}, Por favor Verifique seu carrinho `}
        open={openBlockedModal}
        onClose={() => setOpenBlockedModal(false)}
      />

      <PaymentMethodModal open={openPaymentMethodModal} isVariablePrice />
    </div>
  );
};
