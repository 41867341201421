import * as Yup from "yup";

export interface LoginManagerSchemaInterface {
  username: string;
  password: string;
}

const requiredFieldMessage = "Campo Obrigatório";

export const LoginManagerSchema: Yup.SchemaOf<LoginManagerSchemaInterface> =
  Yup.object().shape({
    username: Yup.string().required(requiredFieldMessage),

    password: Yup.string().required(requiredFieldMessage),
  });
