import { makeStyles, Theme } from "@material-ui/core/styles";

const margin = 4;

const useStyles = makeStyles((theme: Theme) => ({
  fieldView: {
    position: "relative",
    width: 343,
    height: 245,
    marginBottom: 12,
  },

  label: {
    width: "100%",
    fontSize: 14,
    fontWeight: "bold",
    color: "#000",
  },

  field: {
    position: "relative",
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid #E5E5E8",
    marginTop: margin,
    fontSize: 14,

    "&::placeholder": {
      color: "#A9A9AD",
    },

    "&:focus": {
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },

  focused: {
    border: `1px solid ${theme.palette.primary.light}`,
  },

  clean: {
    position: "absolute",
    top: 30,
    right: 5,
    fontSize: 12,
    fontWeight: 900,
    paddingTop: 5,
    paddingRight: 15,
    paddingBottom: 5,
    paddingLeft: 15,
    borderRadius: 12,
    backgroundColor: "#0002"
  },

  helper: {
    width: "100%",
    fontSize: 12,
    color: "#A9A9AD",
    marginBottom: margin,
  },

  successField: {
    border: `1px solid #1BCAA0`,
  },

  errorField: {
    border: `1px solid ${theme.palette.error.main}`,
  },

  successHelper: {
    color: "#1BCAA0",
  },

  errorHelper: {
    color: theme.palette.error.main,
  },

  visibility: {
    zIndex: 100,
    position: "absolute",
    top: 24,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 56,
    height: 56,
    color: "#C1C1C5",
  },
}));

export default useStyles;
