import React, { createContext, useContext } from "react";
import { Path } from "../configs/routes";

import {
  Item, Totem, User, Wallet
} from "../types";

import {
  OrderInterface,
  useOrder,
  UserManagerStore,
  UserTotemStore,
  useUserManager,
  useUserTotem,
  NavigationInterface,
  useNavigation,
  walletDefault,
} from "./hooks";

export interface UserContextInterface {
  userManager: UserManagerStore;
  userTotem: UserTotemStore;
  order: OrderInterface;
  navigation: NavigationInterface;
}

export const store = createContext<UserContextInterface>({
  userManager: {
    id: "",
    username: "",
    token: "",
    refreshToken: "",
    role: null,
    entity: "",
    login: (user: User) => user,
    logout: () => null,
    callRefreshToken: () => null,
  },

  userTotem: {
    id: "",
    identityNumber: "",
    picture: "",
    name: "",
    virtualCard: null,
    role: "",
    wallet: walletDefault,
    paymentInfos: null,
    email: null,
    userId: "",
    login: (totem: Totem) => totem,
    logout: () => null,
    updateWallet: (wallet: Wallet) => wallet,
  },

  order: {
    items: [],
    totalAmount: 0,
    addItem: (item: Item, isOnCredit: boolean) => item && isOnCredit,
    removeItem: (id: string) => id,
    editQuantity: (id: string, quantity: number) => id && quantity,
    setFinalDataIsOnCreditOrder: (_clientSignature: string, _clientName: string) => _clientSignature && _clientName,
    setFinalDataIsManualOrder: (_clientName: string) => _clientName,
    cleanItems: () => console.log("not implemented yet"),
    reset: () => console.log("not implemented yet"),
  },

  navigation: {
    pathname: '/',
    navigateTo: (path: Path) => path,
  },
});

const { Provider } = store;

export interface StateProviderProps {
  children: React.ReactNode;
}

export const StateProvider: React.FC<StateProviderProps> = ({ children }) => {
  const userManager = useUserManager();
  const userTotem = useUserTotem();
  const order = useOrder();
  const navigation = useNavigation();

  return (
    <Provider
      value={{
        userManager,
        userTotem,
        order,
        navigation,
      }}
    >
      {children}
    </Provider>
  );
};

export const useStore = () => useContext(store);
