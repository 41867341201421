import { Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";

import { useStore } from "../../store";
import { Item } from "../../types";
import { formatCurrency } from "../../utils/format";

import useStyles from "./styles";

export interface OrderItemProps {
  item: Item;
  onClick: (item: Item) => void;
}

export const OrderItem: React.FC<OrderItemProps> = ({ item, onClick }) => {
  const Styles = useStyles({ picture: item.picture });

  const { order } = useStore();

  const handleRemove = () => order.removeItem(item.id);

  const handleOpenModal = () => onClick(item);

  return (
    <div className={Styles.item}>
      <div className={Styles.info} onClick={handleOpenModal}>
        <div className={Styles.picture}>
          <div className={Styles.quantity}>
            <span>{item.amount}</span>
          </div>
        </div>

        <Typography className={Styles.name}>{item.name}</Typography>

        <Typography className={Styles.price}>
          {formatCurrency(item.amount * (item.price / 100))}
        </Typography>
      </div>

      <Close className={Styles.close} onClick={handleRemove} />
    </div>
  );
};
