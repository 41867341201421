import { Modal, Typography } from "@material-ui/core";
import { AddOutlined, CloseOutlined, RemoveOutlined } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Items, KitchenOrder } from "../../../types";
import { formatCurrency } from "../../../utils/format";
import sumValuesFromAnArray from "../../../utils/sumValuesFromAnArray";

import { Button } from "../../index";

import useStyles from "./styles";
import { useUpdateOrder } from "../../../api/orders/hooks";
import { useStore } from "../../../store";

export interface EditRequestProps {
  open: boolean;
  order: KitchenOrder;
  onClose: () => void;
  disableProductsActionButtons?: boolean;
}

export const EditRequest: React.FC<EditRequestProps> = ({
  open,
  order,
  onClose,
  disableProductsActionButtons,
}) => {
  const Styles = useStyles();

  const queryClient = useQueryClient();
  const mutator = useUpdateOrder();

  const [items, setItems] = useState<Items>([]);

  const { userManager } = useStore();

  const handleSaveItems = useCallback(async () => {
    onClose();

    await mutator.mutateAsync({
      items,
      id: order.id,
    });

    await queryClient.invalidateQueries(["allOrders", userManager?.entity]);
  }, [items]);

  const handleChangeAmount = (id: string, amount: number) => {
    setItems((_items) =>
      _items.map((item) => {
        const currentItem = item;

        if (currentItem.id === id) {
          if (
            amount >= 1 &&
            amount <=
              (order.items.find((_item) => _item.id === item.id)?.maxAmount ||
                1)
          ) {
            currentItem.quantity = amount;
            currentItem.totalAmount = currentItem.price * amount;
          }
        }

        return currentItem;
      }));
  };

  const handleRemoveItem = (id: string) =>
    setItems((_items) =>
      _items.map((item) => {
        const currentItem = item;

        if (currentItem.id === id) {
          currentItem.quantity = 0;
          currentItem.totalAmount = 0;
        }

        return currentItem;
      }));

  const getTotalAmount = useCallback(
    () =>
      formatCurrency(
        sumValuesFromAnArray(items.map((item) => item.totalAmount / 100))
      ),
    [JSON.stringify(items)]
  );

  const handleClose = () => {
    onClose();

    setItems([]);
  };

  useEffect(() => {
    if (order.items.length > 0) {
      setItems(
        order.items.map((_item) => ({
          id: _item.id,
          name: _item.name,
          quantity: _item.quantity,
          price: _item.unitPrice ? _item.unitPrice : _item.price,
          totalAmount: _item.totalAmount,
        }))
      );
    }
  }, [order.id]);

  return (
    <Modal className={Styles.modal} open={open} onClose={onClose}>
      <div className={Styles.modalView}>
        <div className={Styles.items}>
          {items
            .filter((_item) => _item.quantity > 0)
            .map((item) => (
              <div key={item.id} className={Styles.item}>
                <div className={Styles.amount}>
                  <div
                    className={Styles.icon}
                    onClick={() =>
                      handleChangeAmount(item.id, item.quantity - 1)}
                  >
                    <RemoveOutlined />
                  </div>

                  <Typography component="span">{item.quantity}</Typography>

                  {disableProductsActionButtons && <div />}

                  {!disableProductsActionButtons && (
                    <div
                      className={Styles.icon}
                      onClick={() =>
                        handleChangeAmount(item.id, item.quantity + 1)}
                    >
                      <AddOutlined />
                    </div>
                  )}
                </div>
                <Typography component="span" className={Styles.name}>
                  {item.name}
                </Typography>

                <Typography component="span" className={Styles.price}>
                  {formatCurrency(item.totalAmount / 100)}
                </Typography>

                <div
                  className={Styles.icon}
                  onClick={() => handleRemoveItem(item.id)}
                >
                  <CloseOutlined />
                </div>
              </div>
            ))}
        </div>

        <div className={Styles.footer}>
          <div className={Styles.total}>
            <Typography component="span">Total</Typography>

            <Typography component="span">{getTotalAmount()}</Typography>
          </div>

          <div className={Styles.actions}>
            <Button color="secondary" size="large" onClick={handleClose}>
              Cancelar
            </Button>

            <div className={Styles.divider} />

            <Button color="primary" size="large" onClick={handleSaveItems}>
              Salvar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
