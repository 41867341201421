import React from "react";
import { useStyles } from "./styles";

interface IUserListingSearchProps {
  hidden?: boolean;
  searchQuery: string;
  setSearchQuery: any;
  setOnSearchFocus?: any;
}

const UserListingSearch: React.FC<IUserListingSearchProps> = ({
  hidden,
  searchQuery,
  setSearchQuery,
  setOnSearchFocus,
}) => {
  const Styles = useStyles();
  return (
    <div hidden={hidden} className={Styles.listingSearchContainer}>
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className={Styles.listingSearchInput}
        placeholder="Pesquisar"
        onFocus={() => setOnSearchFocus(true)}
        onBlur={() => setOnSearchFocus(false)}
      />
    </div>
  );
};

export { UserListingSearch };
