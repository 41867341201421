import { Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { BagIcon, ClockIcon } from "../../assets";
import { ClientRow } from "../../components";
import { getFirebaseRealTimeDatabase } from "../../services/firebaseRealTimeDatabase";
import { useStore } from "../../store";
import { KitchenOrders, Sector } from "../../types";
import { getClientNameForOrder } from "../../utils/getClientNameForOrder";
import useStyles from "./styles";
import { useFetchAllDailyOrders, useGetSectorsActive } from "../../api/orders/hooks";
import { Select } from "../../components/Select";
import { useEntity } from "../../hooks/useEntity";

export const Request: React.FC = () => {
  const Styles = useStyles();

  const [entityId, setEntityId] = useState<string>("");

  const { data: _orders, refetch: reFetchOrders } = useFetchAllDailyOrders(
    entityId,
    true
  );
  const { data: sectors } = useGetSectorsActive(entityId, true);

  const { userManager } = useStore();

  const [orderIsPending, setOrderIsPending] = useState<KitchenOrders>([]);
  const [orderFinalized, setOrderFinalized] = useState<KitchenOrders>([]);
  const [sectorsList, setSectorsList] = useState<any[]>([]);
  const [sectorId, setSectorId] = useState("all");
  const [sectorSelected, setSectorSelected] = useState("all");
  const { showSectors } = useEntity();

  const isRequestRoute = useCallback(
    () => location.pathname === "/requests",
    [location.pathname]
  );

  const formatList = () => {
    const listSectors = sectors?.map((_sector: Sector) => ({
      label: _sector.name,
      value: _sector.id,
    }));

    const listSortedSectors: any[] = listSectors?.sort((a: any, b: any) =>
      a.label.localeCompare(b.label));

    listSortedSectors.unshift({ label: "Todos", value: "all" });
    listSortedSectors.push({ label: "Outros", value: "others" });

    setSectorsList(listSortedSectors);
  };

  const filterOrdersBySector = (order: any) => {
    if (sectorId === "all") return true;

    if (sectorId === "others") {
      return order.items?.some((item: any) => !item.segment);
    }

    return order.items?.some((item: any) => item.segment?.id === sectorId);
  };

  const loadOrders = async () => {
    if (isRequestRoute()) {
      if (_orders) {
        setOrderIsPending(
          [
            ..._orders
              .filter((order) => !order.isDelivered)
              .filter((order) => !order.isPending)
              .sort(
                (firstOrder, secondOrder) =>
                  new Date(secondOrder?.createdAt || 0).getTime() -
                  new Date(firstOrder?.createdAt || 0).getTime()
              )
              .filter((order: any) =>
                filterOrdersBySector(order)),
            ..._orders
              .filter((order) => !order.isDelivered)
              .filter((order) => order.isPending)
              .filter((order) => !order.isSchedule)
              .sort(
                (firstOrder, secondOrder) =>
                  new Date(firstOrder?.createdAt || 0).getTime() -
                  new Date(secondOrder?.createdAt || 0).getTime()
              )
              .filter((order: any) => filterOrdersBySector(order)),
          ].slice(0, 20) as KitchenOrders
        );
        setOrderFinalized(
          _orders
            .filter((order) => order.isDelivered)
            .filter((order) => !order.isSchedule)
            .filter((order: any) =>
              filterOrdersBySector(order))
            .sort(
              (firstOrder, secondOrder) =>
                new Date(secondOrder?.deliveredAt || 0).getTime() -
                new Date(firstOrder?.deliveredAt || 0).getTime()
            )
            .slice(0, 20) as KitchenOrders
        );
      }
    }
  };

  const filterOrders = (id: string) => {
    setSectorSelected(id);
    setSectorId(id);
    loadOrders();
  };

  useEffect(() => {
    (async () => {
      await loadOrders();
    })();
  }, [_orders, sectorId]);

  useEffect(() => {
    if (userManager.entity) {
      setEntityId(userManager.entity);
    }
  }, [userManager]);

  useEffect(() => {
    getFirebaseRealTimeDatabase(userManager.entity, async () => {
      await reFetchOrders();
    });
  }, []);

  useEffect(() => {
    if (sectors?.length) {
      formatList();
    }
  }, [sectors]);

  return (
    <div className={Styles.container}>
      {sectorsList?.length > 0 && showSectors && (
        <>
          <div className={Styles.filterSector}>
            <Select
              label="Setor"
              options={sectorsList}
              value={sectorSelected}
              onChange={(val) => filterOrders(val)}
            />
          </div>
          <hr className={Styles.line} />
        </>
      )}
      <div className={Styles.warpper}>
        <div className={Styles.orderList}>
          <div className={Styles.titleView}>
            <ClockIcon />

            <Typography component="span" className={Styles.title}>
              Em Andamento
            </Typography>
          </div>

          <div className={Styles.list}>
            {orderIsPending.map((order) => (
              <ClientRow
                key={order.id}
                {...order.client}
                name={getClientNameForOrder(order)}
              />
            ))}
          </div>
        </div>

        <div className={Styles.orderList}>
          <div className={Styles.titleView}>
            <BagIcon />

            <Typography component="span" className={Styles.title}>
              Finalizados
            </Typography>
          </div>

          <div className={Styles.list}>
            {orderFinalized.map((order) => (
              <ClientRow
                key={order.id}
                {...order.client}
                name={getClientNameForOrder(order)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
