import { makeStyles } from "@material-ui/core/styles";

const appBarHeight = 76;

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    padding: 0,
    paddingTop: appBarHeight,
    overflowY: "hidden",
  },

  table: {
    display: 'flex',
    flexDirection: 'column',
    width: "100%",
    height: "100%",
  },

  tableBody: {
    flex: 1,
    width: "100%",
    overflowY: "auto",
  },

  tableCell: {
    fontSize: 12,
    fontWeight: 600,
  },

  tableColumn2: {
    width: 304,
    paddingRight: 10,
  },

  tableRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 45,
    paddingLeft: 28,
    paddingRight: 20,
    marginBottom: 10,
    borderRadius: 14,
    backgroundColor: "#FFF",
  },

  filterSector: {
    marginTop: 40,
    marginBottom: 20,
  },

  line: {
    borderTop: 0.2,
    width: '100%',
    borderColor: '#C1C1C5'
  },

  placeHolder: {
    color: '#000000',
    fontSize: 12,
  }
}));

export default useStyles;
