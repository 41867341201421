import { makeStyles, Theme } from "@material-ui/core/styles";

import { LoginBgUser } from "../../assets/images";

const buttonPadding = 15;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    overflow: "hidden",
    width: "100%",
    height: "100vh",
    backgroundImage: `url(${LoginBgUser})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundClip: "border-box",
  },

  formView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  title: {
    fontSize: 34,
    fontWeight: "bold",
    marginBottom: 12,
  },

  keyboard: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: "28px 24px",
    width: 215,
    height: 305,
    marginTop: 140,
    marginLeft: 38,
  },

  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 56,
    height: 56,
    padding: buttonPadding,
    borderRadius: 16,
    backgroundColor: "#E6E6E6",

    "& span": {
      fontSize: 18,
      fontWeight: "bold",
    },
  },

  actions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 120,
    marginTop: 25,
  },

  errorHelper: {
    color: theme.palette.error.main,
    marginBottom: 12,
  },
}));

export default useStyles;
