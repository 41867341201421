import * as Yup from "yup";

export interface updatePasswordSchemaInterface {
  confirmPassword: string;
  password: string;
}

const requiredFieldMessage = "Campo Obrigatório";

export const updatePasswordSchema: Yup.SchemaOf<updatePasswordSchemaInterface> =
  Yup.object().shape({
    confirmPassword: Yup.string()
      .required(requiredFieldMessage)
      .oneOf([Yup.ref('password')], 'As senhas precisam ser iguais')
      .min(4, 'A senha deve ter no mínimo 4 dígitos'),
    password: Yup.string().required(requiredFieldMessage)
      .min(4, 'A senha deve ter no mínimo 4 dígitos'),
  });
