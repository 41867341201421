import React from 'react';
import { useDropzone } from 'react-dropzone';
import { AddOutlined } from "@material-ui/icons";
import { Control, Controller } from 'react-hook-form';
import { styles } from './styles';

export interface FormFieldSignatureVariablePriceProps {
  control: Control<any>;
  name: string;
  onFileSelect: (file: File, uri: any) => void;
  uri?: string;
}

export const FormFieldSignatureVariablePrice: React.FC<FormFieldSignatureVariablePriceProps> = ({
  name,
  control,
  onFileSelect,
  uri,
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange } }) => (
      <UploadComponent
        onFileSelect={onFileSelect}
        uri={uri}
        onChange={onChange}
      />
    )}
  />
);

export type UploadComponentTypes = {
  onFileSelect: (file: File, uri: any) => void;
  uri?: string;
  onChange?: any;
}
const UploadComponent = ({ onFileSelect, uri, onChange }:UploadComponentTypes) => {
  const Styles = styles();

  const onDrop = async (acceptedFiles:any) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      // Converte a imagem para base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        onFileSelect(file, base64String);
        onChange(base64String);
        // Aqui você pode enviar a base64String para o seu backend ou fazer o que for necessário
      };
      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*'] as any,
    multiple: false,
    onDrop,
  });

  return (
    <div {...getRootProps()} className={Styles.container}>
      <input {...getInputProps()} />
      {uri ? (
        <img src={uri} alt="Uploaded" className={Styles.img} />
      ) : (
        <div className={Styles.plusImg}>
          <div className={Styles.icon}>
            <AddOutlined />
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadComponent;
