export const generateTwoFactorArray = (): Array<Array<number>> => {
  const array: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  let currentIndex = array.length;
  let randomIndex;
  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  const stringArray: Array<Array<number>> = [];
  stringArray.push([array[0], array[1]]);
  stringArray.push([array[2], array[3]]);
  stringArray.push([array[4], array[5]]);
  stringArray.push([array[6], array[7]]);
  stringArray.push([array[8], array[9]]);

  return stringArray;
};
