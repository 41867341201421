import { makeStyles, Theme } from "@material-ui/core/styles";

const categoriesHeight = 110;
const clientViewWidth = 320;

interface DynamicStylesProps {
  onShowSectors: boolean;
  quantitySectors: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    padding: 0,
    overflow: "hidden",
    width: "100%",
    height: "100vh",
    paddingTop: ({ onShowSectors }: DynamicStylesProps) => onShowSectors ? 200 : categoriesHeight,
    paddingRight: clientViewWidth,
  },

  content: {
    paddingTop: 50,
    paddingBottom: 50,
    paddingLeft: 36,
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
  },

  categories: {
    position: "fixed",
    top: ({ onShowSectors }: DynamicStylesProps) => onShowSectors ? 110 : 0,
    left: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: `calc(100% - ${clientViewWidth}px)`,
    height: categoriesHeight,
    paddingLeft: 36,
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
  },

  products: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: "45px 25px",
  },

  sectors: {
    position: "fixed",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: `calc(100% - ${clientViewWidth}px)`,
    height: categoriesHeight,
    paddingLeft: 36,
    backgroundColor: theme.palette.background.paper,
  },

  sectorsContainer: {
    display: 'flex',
    overflowX: 'auto',
    flexWrap: 'nowrap',
    justifyContent: ({ quantitySectors }: DynamicStylesProps) => quantitySectors >= 3 ? '' : 'center',
    padding: 10,
    width: 400,
  },

  divider: {
    width: "100%",
    height: 2,
    backgroundColor: "#E5E5E8",
  },
}));

export default useStyles;
