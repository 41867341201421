import React from "react";
import useStyles from "./styles";

const AlphabetIndex = ({ letters, availableLetters, onSelect }: { letters: string[], availableLetters: string[], onSelect: (letter: string) => void }) => {
  const Styles = useStyles();

  return (
    <div className={Styles.alphabetIndex}>
      {letters.map((letter: string) => {
        const isAvailable = availableLetters.includes(letter);

        return (
          <div
            key={letter}
            className={`${Styles.alphabetItem} ${
              !isAvailable ? Styles.alphabetItemUnavailable : ""
            }`}
            onClick={isAvailable ? () => onSelect(letter) : () => { console.log('Letter not available'); }}
          >
            {letter}
          </div>
        );
      })}
    </div>
  );
};

export default AlphabetIndex;
