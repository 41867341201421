import { Container, Typography } from "@material-ui/core";
import { format, formatISO9075 } from "date-fns";
import React, {
  ReactNode,
  FunctionComponent,
  SVGProps,
  useState,
  useEffect,
} from "react";

import { LogoutIcon } from "../../../assets";
import {
  RouteInterface,
  validPath,
  getPrivateRoutes,
  getLabel,
  Path,
  getSubTitle,
} from "../../../configs/routes";

import { useStore } from "../../../store";
import { NavigationInterface } from "../../../store/hooks";

import useStyles from "./styles";
import { useGetEntity } from "../../../api/orders/hooks";

export interface KitchenLayoutInterface {
  children: ReactNode;
}

export const KitchenLayout: React.FC<KitchenLayoutInterface> = ({
  children,
}) => {
  const { userManager, navigation } = useStore();

  const { data: entity, } = useGetEntity(userManager.entity, true);

  const Styles = useStyles({ pathname: window.location.pathname.replace('/', '') });

  const logout = () => {
    userManager.logout();

    navigation.navigateTo("/");
  };

  const onShowSchedule = (route: any) => {
    if (!entity?.isSchedule) {
      return route.label !== "" && route.path !== '/scheduled-order';
    }
    return route.label !== "";
  };

  return (
    <Container className={Styles.container} maxWidth={false}>
      <Container className={Styles.lateralMenu}>
        {userManager.role &&
          getPrivateRoutes(userManager.role).filter((route) => (onShowSchedule(route))).map(
            (item: RouteInterface) =>
              validPath(item.path) && (
                <ListItemIsAMatch
                  key={item.id}
                  Icon={item.Icon}
                  path={item.path}
                  navigation={navigation}
                />
              )
          )}

        <div className={Styles.logout}>
          <div onClick={logout} className={Styles.item}>
            <div className={`${Styles.icon} ${Styles.itemIcon}`}>
              <LogoutIcon />
            </div>
          </div>
        </div>
      </Container>

      {window.location.pathname !== '/requests' && (
        <Container className={Styles.appBar}>
          <Container className={Styles.labelSubTitlecontainer}>
            <Typography component="span" className={Styles.label}>
              {getLabel(window.location.pathname as Path)}
            </Typography>

            {
            window.location.pathname === '/variable-price-purchase' && (
              <Typography component="span" className={Styles.subTitle}>
                {getSubTitle(window.location.pathname as Path)}
              </Typography>
            )
          }
          </Container>
          <Clock className={Styles.clock} />
        </Container>
      )}

      <Container className={Styles.contentView} maxWidth={false}>
        <div className={Styles.content}>{children}</div>
      </Container>
    </Container>
  );
};

interface ClockPros {
  className: string;
}

const Clock: React.FC<ClockPros> = ({ className }) => {
  const [time, setTime] = useState<Date>(new Date());

  useEffect(() => {
    const oneSecond = 1000;

    setInterval(() => {
      setTime(new Date());
    }, oneSecond);
  }, []);

  return (
    <Typography component="span" className={className}>
      {`${format(time, "dd/MM/yyyy")} ${formatISO9075(time, {
        representation: "time",
      })}`}
    </Typography>
  );
};

export interface ItemInterface {
  path: Path;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  navigation: NavigationInterface;
}

const ListItemIsAMatch: React.FC<ItemInterface> = ({
  path,
  Icon,
  navigation,
}) => {
  const { userTotem, order } = useStore();
  const Styles = useStyles({ pathname: '' });

  const handleClick = () => {
    navigation.navigateTo(path);
    if (path === '/variable-price-purchase') {
      userTotem.logout(true);
      order.reset();

      navigation.navigateTo("/variable-price-purchase");
    }
  };

  return (
    <div
      onClick={handleClick}
      className={`${Styles.item}${path === window.location.pathname ? ` ${Styles.selected}` : ""
      }`}
    >
      <div className={`${Styles.icon} ${Styles.itemIcon}`}>
        {Icon && <Icon />}
      </div>
    </div>
  );
};
