import { Typography } from "@material-ui/core";
import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";

import { CartIcon, CreditCardIcon } from "../../assets";
import { getTodaysOrdersPerUser } from "../../services";

import {
  Item, Order, Orders, Totem
} from "../../types";

import { formatCurrency } from "../../utils/format";
import { getPeriodLabel } from "../../utils/getPeriodLabel";
import { isWhatPercentOf } from "../../utils/isWhatPercentOf";
import {
  OrderItem,
  ProductModal,
  Progress,
  SwitchOptions,
  Option,
} from "../index";

import useStyles from "./styles";

type SelectedTab = "Carrinho" | "Carteira";

const options: Option[] = [
  {
    Icon: CartIcon,
    label: "Carrinho",
    disabled: false,
  },

  {
    Icon: CreditCardIcon,
    label: "Carteira",
    disabled: false,
  },
];

const optionsAdmin = options;

optionsAdmin[1].disabled = true;

export interface PurchaseViewProps {
  totem: Totem;
  order: Order;
  onShowSectors?: boolean;
}

export const PurchaseView: React.FC<PurchaseViewProps> = ({
  totem,
  order,
  onShowSectors,
}) => {
  const [selectedTab, setSelectedTab] = useState<SelectedTab>("Carrinho");

  const [openModal, setOpenModal] = useState<boolean>(true);

  const [selectedItem, setSelectedItem] = useState<Item | null>(null);

  const [orders, setOrders] = useState<Orders>([]);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [sectorList, setSectorsList] = useState<any | null>(null);

  const Styles = useStyles({ selected: false, disabled: false });

  const handleClickItem = (item: Item) => {
    setSelectedItem(item);
    setIsEditing(true);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsEditing(false);
    setSelectedItem(null);
  };

  const loadOrders = async () => {
    if (totem.id) {
      const _orders = await getTodaysOrdersPerUser(totem.id);

      if (_orders) {
        setOrders(_orders as Orders);
      }
    }
  };

  const totalAmount = useCallback(
    () => orders.reduce((acc, o) => acc + o.totalAmount, 0),
    [orders]
  );

  useEffect(() => {
    loadOrders();
  }, [totem]);

  useEffect(() => {
    if (onShowSectors) {
      const updatedCart: any | any = order.items.reduce(
        (acc: any, product: any) => {
          const sector = product.segment ? product.segment.name : "Outros";

          if (!acc[sector]) {
            acc[sector] = [];
          }

          acc[sector].push(product);

          return acc;
        },
        {}
      );

      setSectorsList(updatedCart);
    }
  }, [order]);

  const selectedView = (_selectedTab: SelectedTab) => {
    switch (_selectedTab) {
      case "Carrinho":
        return (
          <div className={Styles.view}>
            <div className={Styles.items}>
              {onShowSectors ?
                sectorList &&
                Object.keys(sectorList).map((key) => (
                  <div key={key}>
                    <Typography component="span" className={Styles.sectorTitle}>
                      {key}
                    </Typography>
                    <div className={Styles.spacer10} />

                    {sectorList[key].map((item: Item) => (
                      <OrderItem
                        key={item.id}
                        item={item}
                        onClick={handleClickItem}
                      />
                    ))}
                    <div className={Styles.spacer20} />
                  </div>
                )) :
                order.items.map((item) => (
                  <OrderItem
                    key={item.id}
                    item={item}
                    onClick={handleClickItem}
                  />
                ))}

            </div>

            {order.items.length > 0 && (
              <div>
                <div className={Styles.divider} />

                <div className={Styles.total}>
                  <span>Total</span>

                  <span>{formatCurrency(order.totalAmount / 100)}</span>
                </div>
              </div>
            )}
          </div>
        );
      case "Carteira":
        return (
          <div className={Styles.view}>
            <div className={Styles.consumption}>
              <div className={Styles.virtualCard}>
                <div className={Styles.balance}>
                  <div className={Styles.progressLabel}>
                    <Typography
                      component="span"
                      className={Styles.balanceValueLabels}
                    >
                      Saldo do cartão:
                    </Typography>
                    <Typography
                      component="span"
                      className={Styles.balanceValueLabels}
                    >
                      {totem.virtualCard
                        ? formatCurrency(
                          (totem.virtualCard?.balance || 0) / 100
                        )
                        : 0}
                    </Typography>
                  </div>

                  <div className={Styles.progressLabel}>
                    <Typography component="span" className={Styles.label}>
                      Consumo
                    </Typography>

                    <Typography component="span" className={Styles.label}>
                      {getPeriodLabel(totem.wallet.period)}
                    </Typography>
                  </div>

                  <Progress
                    percentage={isWhatPercentOf(
                      totem.wallet.periodLimit,
                      totem.wallet.spent
                    )}
                  />

                  <div className={Styles.progressLabel}>
                    <Typography component="span" className={Styles.label}>
                      {formatCurrency(totem.wallet.spent)}
                      {' '}
                      Gastos
                    </Typography>

                    <Typography component="span" className={Styles.label}>
                      {formatCurrency(totem.wallet.periodLimit)}
                    </Typography>
                  </div>
                </div>

                <div className={Styles.remainingBalance}>
                  <Typography component="span" className={Styles.label}>
                    Saldo disponível do limite
                  </Typography>

                  <Typography component="span" className={Styles.label}>
                    {formatCurrency(
                      totem.wallet.periodLimit - totem.wallet.spent
                    )}
                  </Typography>
                </div>
              </div>

              <div
                className={Styles.extract}
                style={{ height: orders.length > 0 ? 90 : 0 }}
              >
                <div className={Styles.extractTitle}>
                  <Typography component="span" className={Styles.label}>
                    Extrato do Dia
                  </Typography>
                </div>

                <div className={Styles.extractItems}>
                  {orders.map((_order) =>
                    _order.items.map((_item) => (
                      <div
                        key={_item.id}
                        className={Styles.extractItemView}
                        style={{ color: _order.isCanceled ? "#FF0000" : "" }}
                      >
                        <div className={Styles.extractItem}>
                          <div className={Styles.extractInfo}>
                            <Typography
                              component="span"
                              className={Styles.extractLabel}
                              style={{ width: 45 }}
                            >
                              {_order.createdAt &&
                                format(new Date(_order.createdAt), "HH:mm")}
                            </Typography>

                            <Typography
                              component="span"
                              className={Styles.extractLabel}
                              style={{ width: 25 }}
                            >
                              {_item.quantity}
                            </Typography>

                            <Typography
                              component="span"
                              className={Styles.extractLabel}
                              style={{ width: 128 }}
                            >
                              {_item.name}
                            </Typography>
                          </div>

                          <Typography
                            component="span"
                            className={Styles.extractLabel}
                          >
                            {formatCurrency(_item.price / 100)}
                          </Typography>
                        </div>
                      </div>
                    )))}
                </div>
              </div>
              <div className={Styles.extractItemViewTotal}>
                <Typography
                  component="span"
                  className={Styles.extractLabel}
                  style={{ width: 128 }}
                >
                  Total:
                  {' '}
                  {formatCurrency(totalAmount() / 100)}
                </Typography>
              </div>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  const getOptions = useCallback(
    () =>
      options.map((option) => {
        const currentOption = option;

        currentOption.disabled =
          currentOption.label === "Carteira" && totem.id === "";

        return option;
      }),
    [totem.id]
  );

  return (
    <div className={Styles.purchase}>
      {totem.role !== "adminClient" && (
      <SwitchOptions
        options={getOptions()}
        onChange={(value) => setSelectedTab(value as SelectedTab)}
        value={selectedTab}
      />
      )}

      {totem.role === "adminClient" && (
      <SwitchOptions
        options={optionsAdmin}
        onChange={(value) => setSelectedTab(value as SelectedTab)}
        value={selectedTab}
      />
      )}

      <div className={Styles.content}>{selectedView(selectedTab)}</div>

      {selectedItem && (
        <ProductModal
          open={openModal}
          onClose={handleCloseModal}
          product={selectedItem}
          isEditing={isEditing}
        />
      )}
    </div>
  );
};
