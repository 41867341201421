import { makeStyles, Theme } from "@material-ui/core/styles";

import { LoginBgUser } from "../../assets/images";

const buttonPadding = 15;

const modalVerticalPadding = 65;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    overflow: "hidden",
    width: "100%",
    height: "100vh",
    backgroundImage: `url(${LoginBgUser})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundClip: "border-box",
  },

  customSelectContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '12px',
    marginTop: '12px',
    backgroundColor: '#ffffff',
    padding: '24px',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    maxHeight: '600px',
    overflowY: 'auto',
  },
  customSelectOption: {
    cursor: 'pointer',
    padding: '16px',
    transition: 'background-color 0.3s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'left',
    width: '100%',
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  selectedOption: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
  },
  cardLabelContainer: {
    display: 'flex',
    alignItems: 'right',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  cardNumber: {
    flexGrow: 1,
  },
  cardInfo: {
    marginLeft: 'auto',
  },

  variablePriceContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    overflow: "hidden",
    width: "100%",
    height: "100vh",
  },

  formView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  title: {
    fontSize: 34,
    fontWeight: "bold",
    marginBottom: 12,
  },

  actions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 130,
    marginTop: 42,
  },

  actionsRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 28,
  },

  link: {
    display: "flex",
    flexDirection: "row",
    marginTop: 12,

    "& span": {
      fontSize: 16,
      fontWeight: "bold",
      textDecorationLine: "underline",
      marginLeft: 8,
    },
  },

  keyboard: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: "28px 24px",
    width: 215,
    height: 305,
    marginTop: 140,
    marginLeft: 38,
  },

  keyboardPassword: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "28px 24px",
    width: 234,
    height: 224,
    marginTop: 80,
    marginLeft: 38,
  },

  buttonPassword: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 108,
    height: 56,
    padding: buttonPadding,
    borderRadius: 16,
    backgroundColor: "#E6E6E6",

    "& span": {
      fontSize: 18,
      fontWeight: "bold",
    },
  },

  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 56,
    height: 56,
    padding: buttonPadding,
    borderRadius: 16,
    backgroundColor: "#E6E6E6",

    "& span": {
      fontSize: 18,
      fontWeight: "bold",
    },
  },

  buttonZero: {
    width: "auto",
    gridColumn: "1 / 3",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  modalView: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: 345,
    height: 445,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 18,
    paddingTop: modalVerticalPadding,
    paddingBottom: modalVerticalPadding,

    "& span": {
      fontSize: 18,
      fontWeight: "normal",
      width: 150,
      textAlign: "center",
    },
  },

  close: {
    position: "absolute",
    top: 15,
    right: 15,
    width: 24,
    height: 24,
  },

  adminActions: {
    position: "fixed",
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    height: 130,
  },

  action: {
    width: 42,
    height: 42,
  },

  uploadImg: {
    display: "flex",
    justifyContent: "center",
  },

  containerRelative: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    overflow: "hidden",
    width: "100%",
    height: "100vh",
    backgroundImage: `url(${LoginBgUser})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundClip: "border-box",
  },
  subTitle: {
    fontSize: 20,
    marginBottom: 5,
  },

  PixContainer: {
    height: 350,
    width: 350,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  spacer18: {
    height: 18,
  },

}));

export default useStyles;
