export type Route = 'real-time' | 'archive' | 'requests' | 'variable-price-purchase' | 'product-variable-price-purchase' | 'confirm-manual-purchase'| 'scheduled-order';

export const getBackgroundColor = (route: Route) => {
  switch (route) {
    case 'real-time':
      return '#4D4D59';
    case 'variable-price-purchase':
    case 'product-variable-price-purchase':
    case 'confirm-manual-purchase':
      return '#fff';
    case 'archive':
    case 'requests':
    case 'scheduled-order':
      return '#E5E5E8';
    default:
      return '#000';
  }
};

export const getBackgroundColorForFontColor = (route: Route) => {
  switch (route) {
    case 'real-time':
      return '#4D4D59';
    case 'variable-price-purchase':
    case 'archive':
    case 'requests':
    case 'scheduled-order':
      return '#E5E5E8';
    default:
      return '#000';
  }
};
