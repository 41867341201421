import React from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./styles";

interface CustomSelectProps {
  options: { id:string; value: string; label: string }[];
  value: string;
  onChange: (value: string) => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ options, value, onChange }) => {
  const classes = useStyles();

  return (
    <Box className={classes.customSelectContainer}>
      {options.map((option) => (
        <Box
          key={option.value}
          className={`${classes.customSelectOption} ${value === option.value ? classes.selectedOption : ''}`}
          onClick={() => onChange(option.id)}
        >
          <Box className={classes.cardLabelContainer}>
            <Typography variant="body1" className={classes.cardNumber}>
              {option.label}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default CustomSelect;
