import { Modal, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";

import { KitchenOrderClient } from "../../../types";

import useStyles from "./styles";

export interface ProfileModalProps extends KitchenOrderClient {
  open: boolean;
  onClose?: () => void;
}

export const ProfileModal: React.FC<ProfileModalProps> = ({
  open,
  onClose,
  picture,
  name,
}) => {
  const Styles = useStyles({ picture });

  return (
    <Modal
      className={Styles.modal}
      open={open}
      onClose={onClose}
    >
      <div className={Styles.modalView}>
        <div className={Styles.close} onClick={onClose}>
          <Close />
        </div>

        <div className={Styles.clientPhoto} />

        <Typography component="span" className={Styles.name}>{name}</Typography>
      </div>
    </Modal>
  );
};
