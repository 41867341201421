import { useEffect, useState } from "react";
import qz, { PrintData } from "qz-tray";

export const usePrinter = () => {
  const [printers, setPrinters] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      await connectToQz();
      await findPrinters();
    })();
  }, []);

  const connectToQz = async () => {
    try {
      await qz.websocket.connect();
      console.log('Connected to QZ');
    } catch (err) {
      console.error('Error connecting to QZ', err);
    }
  };

  const findPrinters = async () => {
    try {
      const availablePrinters = await qz.printers.find();
      if (Array.isArray(availablePrinters)) {
        setPrinters(availablePrinters);
      }
      if (typeof availablePrinters === 'string') {
        setPrinters([availablePrinters]);
      }
      console.log('Printers found:', printers);
    } catch (err) {
      console.error('Error finding printers', err);
    }
  };

  const handlePrint = async (orderDetails: string, printer: string) => {
    try {
      const config = qz.configs.create(printer);
      const data: PrintData[] = [
        {
          type: 'raw', format: 'command', data: orderDetails, flavor: 'plain'
        },
        {
          type: 'raw', format: 'command', data: '\n\n\n\n', flavor: 'plain'
        },
        {
          type: 'raw', format: 'command', data: '\x1D\x56\x00', flavor: 'plain'
        } // Full cut command
      ];
      await qz.print(config, data);
      console.log('Print job sent to printer');
    } catch (err) {
      console.error('Error printing', err);
    }
  };

  return { handlePrint, printers };
};
