const apiUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const databaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID;
const appId = process.env.REACT_APP_FIREBASE_APP_ID;
const faceRecognitionClient = process.env.REACT_APP_FACE_RECOGNITION_CLIENT;

export {
  apiUrl,
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  faceRecognitionClient,
};
