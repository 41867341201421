import React from "react";
import { Modal, Typography } from "@material-ui/core";

import { useStore } from "../../../store";

import { Button } from "../../index";

import useStyles from "./styles";

export interface IndividualPaymentMethodModalProps {
  open: boolean;
}

export const IndividualPaymentMethodModal: React.FC<IndividualPaymentMethodModalProps> = ({ open }) => {
  const Styles = useStyles();

  const { navigation } = useStore();

  const handleIndividualPurchase = (type: string) => {
    navigation.navigateTo("/confirm-individual-purchase", {
      state: {
        type,
      }
    });
  };

  return (
    <Modal className={Styles.modal} open={open}>
      <div className={Styles.modalView}>
        <Typography component="span" className={Styles.title}>
          Finalizar Compra
        </Typography>

        <Typography component="span" className={Styles.message}>
          Escolha o método de pagamento
        </Typography>

        <div className={Styles.actions}>
          <Button
            onClick={() => handleIndividualPurchase(
              "creditCard"
            )}
          >
            Cartão de Crédito
          </Button>

          <Button
            onClick={() => handleIndividualPurchase(
              "pix"
            )}
            color="secondary"
          >
            Pix
          </Button>
        </div>
      </div>
    </Modal>
  );
};
