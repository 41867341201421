import React, { useEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { QueryClient, QueryClientProvider } from 'react-query';
import theme from "./configs/theme";
import Routes from "./routes";

import { hashGenerator } from "./utils/hashGenerator";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
});

export default () => {
  useEffect(() => {
    const hash = localStorage.getItem('MenuPass@TotemHash');

    if (!hash) {
      localStorage.setItem('MenuPass@TotemHash', hashGenerator());
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Routes />
        </CssBaseline>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
