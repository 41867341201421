import { Typography } from "@material-ui/core";
import React from "react";

import { StarIcon } from "../../assets";

import { Category as CategoryInterface } from "../../types";

import useStyles from "./styles";

interface CategoryDefault {
  selected?: boolean;
  onClick?: (id: string) => void;
}

export type CategoryProps = CategoryInterface & CategoryDefault;

export const Category: React.FC<CategoryProps> = ({
  id,
  name,
  picture,
  selected = false,
  onClick,
}) => {
  const Styles = useStyles({ picture });

  return (
    <div className={Styles.category} onClick={() => onClick && onClick(id)}>
      {picture ? (
        <div className={Styles.image} />
      ) : (
        <div className={Styles.imageEmpty}>
          <StarIcon />
        </div>
      )}

      <Typography component="span" className={Styles.name} noWrap>
        {name}
      </Typography>

      {selected && <div className={Styles.selected} />}
    </div>
  );
};
