import { makeStyles } from "@material-ui/core/styles";
import { Product } from "../../types";

type DynamicStylesProps = Partial<Product>;

const clientViewWidth = 320;

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    padding: 0,
    overflow: "hidden",
    width: "100%",
    height: "100vh",
    paddingRight: clientViewWidth,
  },

  warrper: {
    display: 'flex',
    flexDirection: 'row',
    // paddingTop: 150,
    // background: 'red',
    width: '100%',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },

  image: {
    width: 150,
    height: 150,
    borderRadius: 32,
    marginBottom: 15,
    backgroundImage: ({ picture }: DynamicStylesProps) => `url(${picture})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundClip: "border-box",
    boxShadow:
      "0px 8px 12px rgba(0, 0, 0, 0.08), 0px 4px 48px rgba(0, 0, 0, 0.08)",
  },

  input: {
    border: 'none',
    borderBottomColor: 'black',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    outline: 'none',
    fontSize: 20,
    width: 130,
    textAlign: 'center',
    // fontWeight: "bold",
  },

  inputContainer: {
    display: 'flex',

    "& span": {
      fontSize: 20,
      fontWeight: "bold",
    },
  },

  ProductContainer: {
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  textNameProd: {
    marginBottom: 10,
    fontSize: 20,
    fontWeight: "bold",
  },

  line: {
    height: 200,
    width: 1,
    backgroundColor: '#000000',
  },

  button: {
    width: "100% !important",
  },

}));

export default useStyles;
