import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles({
  container: {
    display: "flex",
    width: 343,
    height: 245,
    borderRadius: 24,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E5E5E8',
  },

  plusImg: {
    width: 56,
    height: 56,
    borderRadius: 56 / 2,
    backgroundColor: '#000000',
    padding: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  icon: {
    width: 24,
    height: 24,
    color: '#fff',
  },

  img: {
    width: 343,
    height: 245,
    borderRadius: 24,
  },
});
