import React from "react";

import useStyles from "./styles";

type Size = "large" | "medium" | "small";
type Color = "primary" | "secondary";

interface CustomButtonProps {
  disabled?: boolean;
  fullWidth?: boolean;
  size?: Size;
  color?: Color;
}

export type ButtonProps = CustomButtonProps &
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >;

export const Button: React.FC<ButtonProps> = ({
  children,
  disabled,
  fullWidth = false,
  size = "medium",
  color = "primary",
  className,
  onClick = () => {
    console.log("not implemented yet");
  },
  ...rest
}) => {
  const Styles = useStyles({ size, color, fullWidth });

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (disabled) return event.preventDefault();
    return onClick(event);
  };

  return (
    <button
      type="button"
      className={`${className ? `${className} ` : ""}${Styles.button}${
        disabled ? ` ${Styles.disabled}` : ""
      }`}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </button>
  );
};
