import api from "../../configs/api";
import { CreatePaymentVisitor } from "../../types";

export const getClientTransaction = async (transactionId: string) => {
  try {
    const { status, data } = await api.get(
      `/virtual-card/recharge/${transactionId}`,
    );

    if (status !== 200) throw new Error();

    return data;
  } catch (error) {
    return false;
  }
};

export const createPaymentVisitor = async (dto: CreatePaymentVisitor) => {
  try {
    const { status, data } = await api.post("/virtual-card/payment", dto);

    if (status !== 201) throw new Error();

    return data;
  } catch (error) {
    return false;
  }
};

export const createPaymentIndividual = async (dto: CreatePaymentVisitor) => {
  try {
    const { status, data } = await api.post("/virtual-card/payment", dto);

    if (status !== 201) throw new Error();

    return data;
  } catch (error) {
    return false;
  }
};
