import { Typography } from "@material-ui/core";
import React, { ReactNode } from "react";

import useStyles from "./styles";

export interface OrderList {
  title: string;
  children: ReactNode;
}

export const OrderList: React.FC<OrderList> = ({ title, children }) => {
  const Styles = useStyles();

  return (
    <div className={Styles.orderList}>
      <Typography component="span" className={Styles.title}>
        {title}
      </Typography>

      <div className={Styles.list}>{children}</div>
    </div>
  );
};
