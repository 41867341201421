import { makeStyles, Theme } from "@material-ui/core/styles";

interface DynamicStylesProps {
  picture: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  modalView: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: 345,
    height: 355,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 18,
    paddingTop: 55,
    paddingBottom: 20,
  },

  close: {
    position: "absolute",
    top: 15,
    right: 15,
    width: 24,
    height: 24,
  },

  clientPhoto: {
    width: 200,
    height: 200,
    borderRadius: 18,
    backgroundImage: ({ picture }: DynamicStylesProps) => `url(${picture})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundClip: "border-box",
  },

  name: {
    fontSize: 18,
    width: 220,
    textAlign: "center",
  },
}));

export default useStyles;
