import { Container } from "@material-ui/core";
import React, { ReactNode } from "react";

import useStyles from "./styles";

export interface TotemLayoutInterface {
  children: ReactNode;
}

export const TotemLayout: React.FC<TotemLayoutInterface> = ({ children }) => {
  const Styles = useStyles();

  return (
    <Container className={Styles.contentView} maxWidth={false}>
      <div className={Styles.content}>{children}</div>
    </Container>
  );
};
