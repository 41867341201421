import { Typography } from "@material-ui/core";
import React, { FunctionComponent, SVGProps } from "react";
import useStyles from "./styles";

interface SwitchOptionProps {
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  label: string;
  selected: boolean;
  disabled: boolean;
  containsLabel: boolean;
  onClick: () => void;
}

export type Option = Omit<SwitchOptionProps, "selected" | "onClick" | "containsLabel">;

export interface SwitchOptionsProps {
  label?: string;
  options: Option[];
  value: string;
  onChange: (value: string) => void;
}

export const SwitchOptions: React.FC<SwitchOptionsProps> = ({
  label: fieldLabel,
  options,
  value,
  onChange
}) => {
  const containsLabel = !!fieldLabel;

  const Styles = useStyles({ containsLabel });

  return (
    <div className={Styles.switchTabView}>
      {fieldLabel && (
        <span className={Styles.label}>{fieldLabel}</span>
      )}

      <div className={Styles.switchTab}>
        {options.map(({ Icon, disabled, label }: Option) => (
          <SwitchOption
            key={label}
            Icon={Icon}
            label={label}
            disabled={disabled}
            containsLabel={containsLabel}
            selected={label === value}
            onClick={() => !disabled && onChange(label)}
          />
        ))}
      </div>
    </div>
  );
};

const SwitchOption: React.FC<SwitchOptionProps> = ({
  Icon,
  label,
  selected,
  disabled,
  containsLabel,
  onClick,
}) => {
  const Styles = useStyles({ containsLabel, selected, disabled });

  return (
    <div className={Styles.switchOption} onClick={onClick}>
      {Icon && (<Icon />)}

      <Typography component="span">{label}</Typography>
    </div>
  );
};
