import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, FormField } from "../../components";
import { getPrivateRoutes, RouteInterface } from "../../configs/routes";
import { LoginManagerSchema, LoginManagerSchemaInterface } from "../../schemas";
import { loginManager } from "../../services";
import { useStore } from "../../store";
import { RoleType, User } from "../../types";
import useStyles from "./styles";

const initialLoginFormValues: LoginManagerSchemaInterface = {
  username: "",
  password: "",
};

export const Login: React.FC = () => {
  const Styles = useStyles();

  const { userManager, navigation } = useStore();

  const {
    control,
    handleSubmit,
    reset,
    setError,
  } = useForm<LoginManagerSchemaInterface>(
    {
      mode: "onSubmit",
      resolver: yupResolver(LoginManagerSchema),
      defaultValues: initialLoginFormValues,
    }
  );

  const [currentUser, setCurrentUser] = useState<User | null>(null);

  const handleConfirmLogin = useCallback(() => {
    if (currentUser) {
      userManager.login(currentUser);

      const initialPath = getPrivateRoutes(currentUser.role as RoleType).find((route) => route.id === 1 && route.path === '/') as RouteInterface;

      if (initialPath.to) navigation.navigateTo(initialPath.to);
    }
  }, [currentUser]);

  const handleLogout = () => {
    setCurrentUser(null);

    userManager.logout();
  };

  const onSubmit = async (data: LoginManagerSchemaInterface) => {
    const { username, password } = data;

    const hash = localStorage.getItem("MenuPass@TotemHash") as string;

    const res = await loginManager(username, password, hash);

    if (res) {
      setCurrentUser(res);

      reset(initialLoginFormValues);
    } else {
      setError("username", {
        type: "value",
        message: "Login ou Senha inválidos!"
      });

      reset({
        ...initialLoginFormValues,
        username: data.username,
      }, {
        keepErrors: true,
      });
    }
  };

  return (
    <div className={Styles.container}>
      {!currentUser ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography className={Styles.title}>Bem-Vindo!</Typography>

          <FormField
            control={control}
            name="username"
            label="Login"
            placeholder="Insira seu Login"
          />

          <FormField
            control={control}
            name="password"
            label="Senha"
            placeholder="Insira sua Senha"
            type="password"
          />

          <Button type="submit" size="large" fullWidth>
            Entrar
          </Button>
        </form>
      ) : (
        <div className={Styles.questionView}>
          <Typography className={Styles.title}>Iniciar o Sistema</Typography>

          <Typography className={Styles.question}>
            Deseja iniciar o sistema para o uso regular?
          </Typography>

          <div className={Styles.actions}>
            <Button size="large" color="secondary" onClick={handleLogout}>
              Sair
            </Button>

            <Button size="large" onClick={handleConfirmLogin}>
              Iniciar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
