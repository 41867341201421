import React from "react";
import { Modal, Typography } from "@material-ui/core";

import { useStore } from "../../../store";
import { Button } from "../../index";

import useStyles from "./styles";

export interface VisitorPaymentMethodModalProps {
  open: boolean;
  isVariablePrice?: boolean;
}

export const VisitorPaymentMethodModal: React.FC<VisitorPaymentMethodModalProps> = ({ open, isVariablePrice = false }) => {
  const Styles = useStyles();

  const { navigation } = useStore();
  const handleManualPurchase = (type: string) => {
    navigation.navigateTo("/confirm-manual-purchase", {
      state: {
        type,
        isVariablePrice,
      }
    });
  };
  return (
    <Modal className={Styles.modal} open={open}>
      <div className={Styles.modalView}>
        <Typography component="span" className={Styles.title}>
          Finalizar Compra
        </Typography>

        <Typography component="span" className={Styles.message}>
          Escolha o método de pagamento
        </Typography>

        <div className={Styles.actions}>
          <Button
            onClick={() => handleManualPurchase('visitor')}
          >
            Pagar com pix
          </Button>

        </div>
      </div>
    </Modal>
  );
};
