import React from "react";
import { Modal, Typography } from "@material-ui/core";

import { useStore } from "../../../store";

import { Button } from "../../index";

import useStyles from "./styles";

export interface PaymentMethodModalProps {
  open: boolean;
  isVariablePrice?: boolean;
}

export const PaymentMethodModal: React.FC<PaymentMethodModalProps> = ({ open, isVariablePrice = false }) => {
  const Styles = useStyles();

  const { navigation } = useStore();

  const handleManualPurchase = (type: string, typeVariablePrice?: string) => {
    navigation.navigateTo("/confirm-manual-purchase", {
      state: {
        type,
        isVariablePrice,
        typeVariablePrice
      }
    });
  };

  return (
    <Modal className={Styles.modal} open={open}>
      <div className={Styles.modalView}>
        <Typography component="span" className={Styles.title}>
          Finalizar Compra
        </Typography>

        <Typography component="span" className={Styles.message}>
          Escolha o método de pagamento
        </Typography>

        <div className={Styles.actions}>
          <Button
            onClick={() => handleManualPurchase(
              isVariablePrice ? "variablePrice" : "manual",
              isVariablePrice ? "manualVariablePrice" : ""
            )}
          >
            Compra Manual
          </Button>

          <Button
            onClick={() => handleManualPurchase(
              isVariablePrice ? "variablePrice" : "on-credit",
              isVariablePrice ? "creditVariablePrice" : ""
            )}
            color="secondary"
          >
            Compra Fiado
          </Button>
        </div>
      </div>
    </Modal>
  );
};
