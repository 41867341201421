import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { faceRecognitionClient } from "../../configs/envs";
import useStyles from "./styles";

export const FaceRecognition: React.FC<any> = ({ onSubmit, faceCheckLoading }: { onSubmit: (image: string) => void, faceCheckLoading: boolean}) => {
  const Styles = useStyles();

  useEffect(() => {
    window.addEventListener('message', async (event) => {
      if (event.data.image) {
        onSubmit(event.data.image);
      }
    });
  }, []);

  return (
    <div className={Styles.container}>
      {
        faceCheckLoading ? (
          <div className={Styles.containerLoading}>
            <CircularProgress style={{ color: 'black' }} />
          </div>
        ) : (
          <iframe
            src={`${faceRecognitionClient}/login`}
            name="FaceId"
            title="FaceId"
            allow="camera; microphone"
            className={Styles.iframe}
          />
        )
      }

    </div>
  );
};
