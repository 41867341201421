import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },

  title: {
    fontSize: 34,
    fontWeight: "bold",
    marginBottom: 12,
  },

  questionView: {
    width: 345,
  },

  question: {
    fontSize: 16,
  },

  actions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 8,
  },
}));

export default useStyles;
