import { makeStyles, Theme } from "@material-ui/core/styles";

const padding = 16;
const margin = 4;

const useStyles = makeStyles((theme: Theme) => ({
  fieldView: {
    position: "relative",
    width: 343,
    marginBottom: 12,
  },

  label: {
    width: "100%",
    fontSize: 14,
    fontWeight: "bold",
    color: "#000",
  },

  field: {
    position: "relative",
    zIndex: 2,
    width: "100%",
    height: 56,
    paddingTop: padding,
    paddingRight: padding + 8,
    paddingBottom: padding,
    paddingLeft: padding + 8,
    borderRadius: theme.shape.borderRadius,
    border: "1px solid #E5E5E8",
    marginTop: margin,
    fontSize: 14,

    "&::placeholder": {
      color: "#A9A9AD",
    },

    "&:focus": {
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },

  hiddenField: {
    position: "absolute",
    zIndex: 1,
    display: "none",
    borderColor: "red",
    top: 21,
    right: 0,
    bottom: 0,
    left: 0,
  },

  popperPaper: {
    zIndex: 99999,
    width: 343,
    padding: 15,
    marginTop: 5,
    borderRadius: 16,
    backgroundColor: "#FFF",
    boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.08), 0px 4px 48px rgba(0, 0, 0, 0.08)",
  },

  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 48,
    padding: 12,
    fontSize: 14,
    border: "none",
    backgroundColor: "transparent",
  },

  itemLabel: {
    fontWeight: "bold",
  },

  itemAlternativeLabel: {
    color: "#A9A9AD",
  },

  focused: {
    border: `1px solid ${theme.palette.primary.light}`,
  },

  helper: {
    width: "100%",
    fontSize: 12,
    color: "#A9A9AD",
    marginBottom: margin,
  },

  successField: {
    border: `1px solid #1BCAA0`,
  },

  errorField: {
    border: `1px solid ${theme.palette.error.main}`,
  },

  successHelper: {
    color: "#1BCAA0",
  },

  errorHelper: {
    color: theme.palette.error.main,
  },

  visibility: {
    zIndex: 100,
    position: "absolute",
    top: 24,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 56,
    height: 56,
    color: "#C1C1C5",
  },
}));

export default useStyles;
