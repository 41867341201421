import * as Yup from "yup";

export interface ConfirmCreditPurchaseSchemaInterface {
  clientName: string;
  clientSignature: string;
}

const requiredFieldMessage = "Campo Obrigatório";

export const ConfirmCreditPurchaseSchema: Yup.SchemaOf<ConfirmCreditPurchaseSchemaInterface> =
  Yup.object().shape({
    clientName: Yup.string().required(requiredFieldMessage),
    clientSignature: Yup.string().nullable(false).required(requiredFieldMessage),
  });

export interface ConfirmManualPurchaseSchemaInterface {
  clientName: string;
}

export const ConfirmManualPurchaseSchema: Yup.SchemaOf<ConfirmManualPurchaseSchemaInterface> =
  Yup.object().shape({
    clientName: Yup.string().required(requiredFieldMessage),
  });
export interface ConfirmVariablePricePurchaseSchemaInterface {
  clientSignature: string;
}

export const ConfirmVariablePricePurchaseSchema: Yup.SchemaOf<ConfirmVariablePricePurchaseSchemaInterface> =
  Yup.object().shape({
    clientSignature: Yup.string().nullable(false).required(requiredFieldMessage),
  });
