import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    type: "light",

    primary: {
      main: "#0D0D15",
    },

    secondary: {
      main: "#E5E5E8",
    },

    error: {
      main: "#F30B5F",
    },

    background: {
      default: "#4D4D59",
      paper: "#FFF",
    },
  },

  typography: {
    fontFamily: ["Roboto"].join(","),
  },

  overrides: {
    MuiButton: {
      sizeSmall: {
        height: 36,
      },

      root: {
        width: 345,
        height: 48,
        textTransform: "none",
      },

      sizeLarge: {
        height: 56,
      },
    },
  },

  shape: {
    borderRadius: 18,
  },
});

export default theme;
