import React from "react";
import { Select as SelectAnt } from "antd";
import { SelectInputProps } from "../../types";
import { styles } from "./styles";

export const Select = (props: SelectInputProps) => {
  const { labelStyle, label } = props;
  const Styles = styles({ selected: false, disabled: false });

  return (
    <>
      <div>
        <div className={labelStyle ?? Styles.textLabel}>{label}</div>
        <div className={Styles.inputWarpper}>
          <SelectAnt {...props} className={Styles.inputWarpper} />
        </div>
      </div>
    </>
  );
};
