import { useMutation, useQuery } from "react-query";
import { KitchenOrders } from "../../types";
import {
  editOrder, getAllDailyOrders, getAllOrders, getAllOrdersForScheduleOrder, getClient, getEntity, getEntityClients, getOrders, getSectors, getSectorsActive, updateClient
} from "./requests";

export const useFetchOrders = (
  entity: string,
  onlyTodayOrders: boolean,
) => useQuery<KitchenOrders, any>(
  ['todayOrders', entity, onlyTodayOrders],
  () => getOrders(entity, onlyTodayOrders),
  {
    enabled: !!entity,
  }
);

export const useFetchAllOrders = (
  entity: string,
  onlyTodayOrders: boolean,
) => useQuery<KitchenOrders | false, any>(
  ['allOrders', entity, onlyTodayOrders],
  () => getAllOrders(entity, onlyTodayOrders),
  {
    enabled: !!entity,
  }
);

export const useFetchAllDailyOrders = (
  entity: string,
  onlyTodayOrders: boolean,
) => useQuery<KitchenOrders | false, any>(
  ['allOrders', entity, onlyTodayOrders],
  () => getAllDailyOrders(entity, onlyTodayOrders),
  {
    enabled: !!entity,
  }
);

export const useFetchAllOrdersForSchedule = (
  entity: string,
) => useQuery<KitchenOrders | false, any>(
  ['allOrdersScheduleOrder', entity],
  () => getAllOrdersForScheduleOrder(entity),
  {
    enabled: !!entity,
  }
);

export const useUpdateOrder = () => useMutation(editOrder);
export const useUpdateClient = () => useMutation(updateClient);
export const useGetSectors = (entityId: string, isEnabled: boolean) => useQuery(['sectors', entityId], () => getSectors(entityId), { enabled: isEnabled });
export const useGetSectorsActive = (entityId: string, isEnabled: boolean) => useQuery(['sectorsActive', entityId], () => getSectorsActive(entityId), { enabled: isEnabled });
export const useGetEntityClients = (entity: string, isEnabled: boolean) => useQuery(['entityClients', entity], () => getEntityClients(entity), { enabled: isEnabled });
export const useGetClient = (clientId: string, isEnabled: boolean) => useQuery(['client', clientId], () => getClient(clientId), { enabled: isEnabled });
export const useGetEntity = (entity: string, isEnabled: boolean) => useQuery(['entity', entity], () => getEntity(entity), { enabled: isEnabled });
