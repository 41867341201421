import { useState, useMemo, useEffect } from "react";
import { useNavigate, NavigateOptions } from "react-router-dom";
import { Path } from "../../configs/routes";
import { getLocalStorageUserManager } from "../../services";

export interface NavigationInterface {
  pathname: Path;
  navigateTo: (path: Path, options?: NavigateOptions) => void;
}

export function useNavigation() {
  const navigate = useNavigate();
  const [pathname, setPathname] = useState<Path>('/');

  const navigateTo = (path: Path, options?: NavigateOptions) => {
    setPathname(path);

    navigate(path, options);
  };

  useEffect(() => {
    const userManager = getLocalStorageUserManager();

    if (!userManager) navigateTo("/");
  }, [window.location.pathname]);

  return useMemo(
    () => ({
      pathname,
      navigateTo,
    }),
    [
      pathname,
      navigateTo,
    ]
  );
}
