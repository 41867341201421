import { makeStyles } from "@material-ui/core/styles";

import { ProgressProps } from "./index";

type DynamicStylesProps = Partial<ProgressProps>;

const useStyles = makeStyles(() => ({
  progressView: {
    width: "100%",
    height: 8,
    borderRadius: 100,
    backgroundColor: "#C1C1C5",
  },

  progress: {
    width: ({ percentage }: DynamicStylesProps) => `${percentage}%`,
    minWidth: "0%",
    maxWidth: "100%",
    height: "100%",
    borderRadius: 100,
    backgroundImage:
      "linear-gradient(90deg, #31EEB5 0%, #31CCEE 48.44%, #EE3180 100%);",

    transition: 'all 120ms'
  },
}));

export default useStyles;
