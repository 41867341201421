import { makeStyles, Theme } from "@material-ui/core/styles";

import { ProductProps } from "./index";

type DynamicStylesProps = Partial<ProductProps>;

const useStyles = makeStyles((theme: Theme) => ({
  product: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    width: "auto",
    minWidth: 250,
  },

  blocked: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    minWidth: 75,
    minHeight: 75,
  },

  imageEmpty: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 75,
    minHeight: 75,
    marginRight: 18,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#000",
  },

  image: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 75,
    minHeight: 75,
    marginRight: 18,
    borderRadius: theme.shape.borderRadius,
    backgroundImage: ({ picture }: DynamicStylesProps) => `url(${picture})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundClip: "border-box",
    boxShadow:
      "0px 8px 12px rgba(0, 0, 0, 0.08), 0px 4px 48px rgba(0, 0, 0, 0.08)",
    opacity: ({ isLocked }: DynamicStylesProps) => isLocked ? 0.2 : 1,
  },

  info: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    opacity: ({ isLocked }: DynamicStylesProps) => isLocked ? 0.2 : 1,
  },

  name: {
    fontSize: 14,
    fontWeight: "normal",
  },

  price: {
    fontSize: 16,
    fontWeight: "bold",
  },

  priceDiscount: {
    fontSize: 16,
    fontWeight: "bold",
    color: '#A9A9AD',
  },
  containerDiscount: {
    display: "flex",
    flexDirection: "column",
  },
  selected: {
    position: "absolute",
    right: 0,
    bottom: 0,
    left: 0,
    width: "100%",
    height: 3,
    backgroundColor: "#000",
  },
}));

export default useStyles;
