import { makeStyles, Theme } from "@material-ui/core/styles";
import { LoginBg } from "../../../assets/images";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    overflow: "hidden",
    width: "100%",
    height: "100vh",
  },

  contentView: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },

  background: {
    width: "50%",
    height: "100%",
    backgroundImage: `url(${LoginBg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundClip: "border-box",
  },

  content: {
    width: "50%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default useStyles;
