import * as Yup from "yup";

export interface LoginUserSchemaInterface {
  username: string;
  password: string;
}

const requiredFieldMessage = "Campo Obrigatório";

export const LoginUserSchema: Yup.SchemaOf<LoginUserSchemaInterface> =
  Yup.object().shape({
    username: Yup.string().required(requiredFieldMessage),

    password: Yup.string().required(requiredFieldMessage),
  });
