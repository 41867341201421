import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  listingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    height: 170,
    width: 345,
    overflowY: "scroll",
    scrollBehavior: "smooth",
    border: "1px solid #e0e0e0",
    borderRadius: 8,
    padding: 0,
    margin: 0,
    marginBottom: 12,
    // hide scrollbar
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  listingItem: {
    display: "flex",
    flexDirection: "row",
    minWidth: "100%",
    width: "100%",
    padding: 8,
  },
  itemContainer: {
    minWidth: "100%",
    width: "100%",
  },
  listingItemSelected: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid #e0e0e0",
    borderRadius: 8,
    backgroundColor: "#f5f5f5",
    minWidth: "100%",
    width: "100%",
    padding: 8,
  },
  listingSearchContainer: {
    width: "100%",
    padding: 8,
    height: 35,
    marginBottom: 16,
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    zIndex: 1,
  },
  listingSearchInput: {
    width: "100%",
    height: 35,
    border: "1px solid #e0e0e0",
    borderRadius: 8,
    transition: "border-color 0.2s ease",
    fontFamily: "Roboto",
    padding: "0px 8px",
    "&:focus": {
      outline: "none",
      transition: "border-color 0.2s ease",
      border: "1px solid #868686",
      boxShadow: "0px 0px 0px 1px #f5f5f5",
      "&::placeholder": {
        color: "transparent",
      },
    },
    "&::placeholder": {
      padding: 8,
      color: "#a9a9ad",
    },
  }
}));

export { useStyles };
