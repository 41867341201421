import { Modal, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";

import { BalanceWalletIcon } from "../../../assets";

import { Button } from "../../index";

import useStyles from "./styles";

export interface BlockedModalProps {
  message?: string;
  open: boolean;
  onClose?: () => void;
}

export const BlockedModal: React.FC<BlockedModalProps> = ({
  open,
  message,
  onClose,
}) => {
  const Styles = useStyles();

  return (
    <Modal
      className={Styles.modal}
      open={open}
      onClose={onClose}
    >
      <div className={Styles.modalView}>
        <div className={Styles.close} onClick={onClose}>
          <Close />
        </div>

        <BalanceWalletIcon />

        <Typography component="span" className={Styles.title}>
          Aviso
        </Typography>

        <Typography component="span" className={Styles.message}>
          {message || 'Você não tem saldo suficiente para selecionar esse item'}
        </Typography>

        <Button onClick={onClose}>OK</Button>
      </div>
    </Modal>
  );
};
