import React from "react";
import { Modal, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { Progress } from "../../Progress";

export interface LoadingModalProps {
  open: boolean;
  progress: number;
  message?: never;
}

export interface AlertModalProps {
  open: boolean;
  message: string;
  progress?: never;
}

export const LoadingModal: React.FC<LoadingModalProps | AlertModalProps> = ({
  open,
  progress,
  message,
}) => {
  const Styles = useStyles();

  return (
    <Modal className={Styles.modal} open={open}>
      <div className={Styles.view}>
        <Typography component="span" align="center">
          {
            progress && !message
              ? "Carregando"
              : message
          }
        </Typography>

        {progress && (
          <Progress
            percentage={progress}
          />
        )}
      </div>
    </Modal>
  );
};
