import { yupResolver } from "@hookform/resolvers/yup";
import React, {
  useCallback, useEffect, useState
} from "react";
import { Slide, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import {
  Button, FormField, LoadingModal
} from "../../components";
import useStyles from "./styles";
import { updatePasswordSchema, updatePasswordSchemaInterface } from "../../schemas/updatePassword";
import { useStore } from "../../store";
import { useUpdateClient } from "../../api/orders/hooks";
import { BackspaceIcon } from "../../assets";

type Fields = keyof updatePasswordSchemaInterface;

const initialUpdatePasswordFormValues: updatePasswordSchemaInterface = {
  confirmPassword: "",
  password: "",
};

const numbersUser: string[] = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

export const UpdatePassword: React.FC = () => {
  const Styles = useStyles();
  const { userTotem, navigation } = useStore();
  const updateMutator = useUpdateClient();
  const [loginError, setLoginError] = useState<string | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [focusedField, setFocusedField] = useState<Fields | null>(null);
  const [showKeyboard, setShowKeyboard] = useState<boolean>(false);

  const {
    control, handleSubmit, reset, getValues, setValue, setError, clearErrors
  } =
    useForm<updatePasswordSchemaInterface>({
      mode: "onChange",
      resolver: yupResolver(updatePasswordSchema),
      defaultValues: initialUpdatePasswordFormValues,
    });

  const handleOnFocusField = (
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    setFocusedField(event.target.name as Fields);
    setLoginError(null);
    clearErrors();

    event.target.blur();
  };

  const handleNumberUserClick = useCallback(
    (number: string) => {
      if (focusedField) {
        const value = getValues()[focusedField] || "";
        if (value.length < 4) {
          setValue(focusedField, value + number, { shouldValidate: true });
        }
      }
    },
    [focusedField]
  );

  const handleBackspace = useCallback(() => {
    if (focusedField) {
      const value = getValues()[focusedField];

      setValue(focusedField, value.slice(0, -1), { shouldValidate: true });
    }
  }, [focusedField]);

  const handleBack = () => {
    reset(initialUpdatePasswordFormValues);
    navigation.navigateTo('/manual-purchase');
  };

  const onSubmit = async (data: updatePasswordSchemaInterface) => {
    const interval = setInterval(() => {
      setProgress((value) => value + 1);
    }, 120);

    const res = await updateMutator.mutateAsync({
      password: data.confirmPassword,
      user: userTotem.userId,
    });

    if (res?.id) {
      navigation.navigateTo("/");
      await userTotem.logout();
      setLoginError(null);
    } else {
      setLoginError("Erro ao alterar a senha");
      setError("confirmPassword", {
        type: "value",
        message: " "
      });
    }

    reset({
      ...initialUpdatePasswordFormValues,
    }, {
      keepErrors: true,
    });
    setProgress(0);
    clearInterval(interval);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowKeyboard(!!focusedField);
    }, 500);
  }, [focusedField]);

  return (
    <div className={Styles.container}>
      <div className={Styles.formView}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography className={Styles.title}>Alterar senha</Typography>

          <FormField
            control={control}
            name="password"
            label="Nova senha"
            placeholder="Insira sua nova senha"
            onFocus={handleOnFocusField}
            type="password"
            focus={(name) => name === focusedField}
          />

          <FormField
            control={control}
            name="confirmPassword"
            label="Confirme sua nova senha"
            placeholder="Confirme sua nova Senha"
            onFocus={handleOnFocusField}
            type="password"
            focus={(name) => name === focusedField}
          />

          <Typography className={Styles.errorHelper}>{loginError}</Typography>

          <div className={Styles.actions}>
            <Button type="submit" size="large" fullWidth>
              Salvar
            </Button>
            <Button
              color="secondary"
              size="large"
              fullWidth
              onClick={handleBack}
            >
              Voltar
            </Button>
          </div>
        </form>

      </div>

      {(focusedField === "password" || focusedField === "confirmPassword") && (
      <Slide in={showKeyboard} direction="left">
        <div className={Styles.keyboard}>
          {numbersUser.map((number: string) => (
            <div
              key={number}
              className={Styles.button}
              onClick={() => handleNumberUserClick(number)}
            >
              <Typography component="span">{number}</Typography>
            </div>
          ))}

          <div
            className={`${Styles.button}`}
            onClick={() => handleNumberUserClick("0")}
          >
            <Typography component="span">0</Typography>
          </div>

          <div className={Styles.button} onClick={handleBackspace}>
            <BackspaceIcon />
          </div>
        </div>
      </Slide>
      )}
      {
            progress > 0 && (
            <LoadingModal
              open={!!progress}
              progress={progress}
            />
            )
          }

    </div>
  );
};
