import { useMutation, useQuery } from "react-query";
import {
  getClientTransaction,
  createPaymentVisitor
} from "./requests";
import { Payment } from "../../types";

export const useGetTransaction = (id: string, isEnabled: boolean) =>
  useQuery<Payment, any>(
    ['getClientTransaction', id],
    () => getClientTransaction(id),
    { enabled: isEnabled },
  );

export const useCreatePaymentVisitor = () => useMutation(createPaymentVisitor);
