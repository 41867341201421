import React, { useEffect, useState } from "react";

import { Container } from "@material-ui/core";

import { useLocation } from "react-router-dom";
import useStyles from "./styles";

import { SearchableInput } from "../../components/searchableinput";
import { getAllClientsByEntity } from "../../services";
import { useStore } from "../../store";
import { getClientById, getWalletByClient } from "../../services/totem";
import { Wallet } from "../../types";
import { Button, ClientVariablePrice } from "../../components";
import { CurrencyInput } from "../../components/CurrencyInput";

export const ProductVariablePricePurchase: React.FC = () => {
  const { state }: any = useLocation();
  const Styles = useStyles({ picture: state.product.picture });

  const [clients, setClients] = useState<any>([]);
  const [clientList, setClientList] = useState<any>([]);
  const [client, setClient] = useState('');
  const [clientId, setClientId] = useState('');
  const [amount, setAmount] = useState(0);
  const [onShowButtonSave, setOnShowButtonSave] = useState(false);

  const {
    userManager,
    userTotem,
    navigation,
    order,
  } = useStore();

  const formatList = () => {
    const listSectors = clients?.map((_client: any) => ({
      label: _client.name,
      value: _client.id,
    }));

    const listSortedSectors = listSectors?.sort((a: any, b: any) => a.label.localeCompare(b.label));
    setClientList(listSortedSectors);
  };

  const handleAmountChange = (value: any) => {
    setAmount(value);
    setOnShowButtonSave(true);

    if (value === 0) {
      setOnShowButtonSave(false);
    }
  };

  const filterOrders = async (value: string) => {
    setClient(value);
    if (value === '') {
      await userTotem.logout(true);
      navigation.navigateTo('/product-variable-price-purchase', {
        state: {
          product: state?.product
        }
      });
    }
  };

  const getClients = async () => {
    const res = await getAllClientsByEntity(userManager.entity);

    if (res) setClients(res);
  };

  const addItemToCart = () => {
    order.addItem({
      ...state.product,
      price: amount,
      isOnCredit: false,
      amount: 1,
    }, !userTotem.id);
    setOnShowButtonSave(false);
  };

  const loginUserTotem = async () => {
    const res = await getClientById(clientId);

    if (res) {
      res.wallet = await getWalletByClient(clientId) as Wallet;

      userTotem.login(res);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    if (clients?.length) {
      formatList();
    }
  }, [clients]);

  useEffect(() => {
    if (clientId?.length) {
      loginUserTotem();
    }
  }, [clientId]);

  useEffect(() => {
    if (userTotem?.id) {
      setClient(userTotem.name);
    }
  }, []);

  const checkHaveBalanceOnCard = () => {
    const virtuarlCardBalance = userTotem?.virtualCard?.balance;
    if (virtuarlCardBalance) {
      return (virtuarlCardBalance > amount) || (virtuarlCardBalance > order.items[0]?.price);
    }
    return false;
  };

  return (
    <Container className={Styles.container} maxWidth={false}>

      <ClientVariablePrice />
      <div className={Styles.warrper}>

        <div className={Styles.ProductContainer}>
          <div className={Styles.image} />
          <div className={Styles.textNameProd}>{state.product.name}</div>
          <div>
            <CurrencyInput styleVariablePrice value={amount || order.items[0]?.price} onChange={handleAmountChange} />
          </div>
          {onShowButtonSave && (
          <Button
            color="primary"
            className={Styles.button}
            onClick={addItemToCart}
            disabled={userTotem.id !== '' ? !checkHaveBalanceOnCard() : false}
          >
            Salvar
          </Button>
          )}

        </div>
        <div className={Styles.line} />
        <div>
          <SearchableInput
            value={client}
            border
            onChange={(InputValue) => filterOrders(InputValue)}
            label="Pesquisar usuário"
            data={clientList}
            onSelect={(item) => {
              setClientId(item.value);
              setClient(item.label);
            }}
          />

        </div>
      </div>

    </Container>
  );
};
