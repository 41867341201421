import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles(() => ({
  inputWarpper: {
    height: 40,
    borderRadius: 14,
    background: '#FFFFFF',
    width: 278,
  },
  textLabel: {
    marginBottom: 5,
    color: '#000',
    fontSize: 14,
    fontWeight: 'bold',
  },
}));
