import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 45,
    paddingLeft: 28,
    paddingRight: 20,
    marginBottom: 10,
  },

  tableRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 45,
    paddingLeft: 28,
    paddingRight: 20,
    marginBottom: 10,
    borderRadius: 14,
    backgroundColor: "#FFF",
  },

  tableHeaderCell: {
    fontSize: 14,
    fontWeight: 'bold',
  },

  tableCell: {
    fontSize: 12,
    fontWeight: 600,
  },

  tableColumn1: {
    width: 147,
  },

  tableColumn2: {
    width: 304,
    paddingRight: 10,
  },

  tableColumn3: {
    width: 171,
  },

  tableColumn4: {
    width: 152,
  },

  tableColumn5: {
    width: 110,
    textAlign: "center",
  },

  tableColumn6: {
    width: 102,
    textAlign: "center",
  },

  tableColumn7: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 49,
    textAlign: "center",
  },
}));

export default useStyles;
