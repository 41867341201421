import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { Control, Controller } from "react-hook-form";
import SignatureCanvas from "react-signature-canvas";
import { FieldProps } from "../index";
import useStyles from "./styles";

export interface FormFieldSignatureProps {
  control: Control<any>;
  name: string;
}

export const FormFieldSignature: React.FC<FieldProps & FormFieldSignatureProps> = ({
  name,
  control,
  label,
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange }, fieldState: { error } }) => (
      <FieldSignature
        label={label}
        onChange={onChange}
        error={error?.message}
      />
    )}
  />
);

export interface FieldSignatureProps {
  label: string;
  className?: string;
  helper?: string;
  error?: string;
  success?: string;
  onChange?: (value: string) => void;
}

export const FieldSignature: React.FC<FieldSignatureProps> = ({
  label,
  className,
  helper,
  error,
  success,
  onChange,
}) => {
  const Styles = useStyles();

  const fieldRef = useRef<SignatureCanvas>(null);

  const [focused, setFocused] = useState<boolean | null>(null);
  const [isEmpty, setIsEmpty] = useState<boolean | null>(null);

  const getHelperText = (): string | undefined => {
    if (error) return error;

    if (success) return success;

    return helper;
  };

  const handleOnChange = useCallback(() => {
    if (onChange && fieldRef.current) {
      const base64 = fieldRef.current?.getTrimmedCanvas().toDataURL("image/png");

      onChange(base64);
    }
  }, [fieldRef]);

  const handleClean = () => {
    fieldRef.current?.clear();

    if (onChange) onChange('');
  };

  useEffect(() => {
    if (focused === false) handleOnChange();
  }, [focused]);

  useEffect(() => setIsEmpty(!!fieldRef.current?.isEmpty()), [fieldRef.current?.isEmpty()]);

  return (
    <div className={`${className ? `${className} ` : ""}${Styles.fieldView}`}>
      <span className={Styles.label}>{label}</span>

      <SignatureCanvas
        ref={fieldRef}
        onEnd={() => setFocused(false)}
        onBegin={() => setFocused(true)}
        backgroundColor="#FFF"
        canvasProps={{
          className: `${Styles.field}${focused ? ` ${Styles.focused}` : ""}${
            success ? ` ${Styles.successField}` : ""
          }${error ? ` ${Styles.errorField}` : ""}`
        }}
      />

      {
        !isEmpty && (
          <span
            className={Styles.clean}
            onClick={handleClean}
          >
            Limpar
          </span>
        )
      }

      {(error || success || helper) && (
        <span
          className={`${Styles.helper}${
            success ? ` ${Styles.successHelper}` : ""
          }${error ? ` ${Styles.errorHelper}` : ""}`}
        >
          {getHelperText()}
        </span>
      )}
    </div>
  );
};
