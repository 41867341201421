import { ref, onValue } from "firebase/database";
import { db } from "../configs/firabse";

export const getFirebaseRealTimeDatabase = (entity: string, refresh: () => void) => {
  onValue(ref(db, `/${entity}/client_order`), () => {
    refresh();
  });
};

export const onQRCodeLogin = (entity: string, totem: string, deviceId: string, login: (clientId: string) => void) => {
  onValue(ref(db, `/${entity}/client_login/${totem}`), (snapshot: any) => {
    if (snapshot.val() && snapshot.val().device_id === deviceId) {
      login(snapshot.val());
    }
  });
};
