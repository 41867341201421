import React, {
  useEffect,
  useState,
} from "react";

import {
  Container
} from "@material-ui/core";

import { useQuery } from "react-query";

import {
  Product,
  BlockedModal,
} from "../../components";

import {
  getProducts,
} from "../../services";

import {
  useStore,
} from "../../store";

import {
  ProductArray,
  Product as ProductInterface,
} from "../../types";

import useStyles from "./styles";

import {
  getFirebaseRealTimeDatabase,
} from "../../services/firebaseRealTimeDatabase";

export const VariablePricePurchase: React.FC = () => {
  const Styles = useStyles();

  const {
    userManager,
    userTotem,
    navigation
  } = useStore();

  const [openBlockedModal, setOpenBlockedModal] = useState<boolean>(false);

  const [message, setMessage] = useState<string>('');

  const handleClickInProduct = (product: ProductInterface) => {
    navigation.navigateTo('/product-variable-price-purchase', {
      state: {
        product
      }
    });
  };

  const { data: productsData, isLoading: loadingProducts, refetch: getProductsQuery } = useQuery<ProductArray, Error>('productsData', () => getProducts(userManager.entity, userTotem.id));

  const filterProducts = (): ProductArray => productsData!.filter((product) => product.isvariablePrice);

  const handleClickProduct = (product: ProductInterface) => {
    if (!product.isLocked) {
      if (product.quantity === 0) {
        setMessage("Esse item não tem mais estoque");
        setOpenBlockedModal(true);
      } else {
        handleClickInProduct(product);
      }
    }
  };

  const close = () => {
    setOpenBlockedModal(false);
    setMessage('');
  };

  const refresh = () => {
    if (userTotem.id) {
      getProductsQuery();
    }
  };

  useEffect(() => {
    if (productsData?.length) {
      filterProducts();
    }
  }, [productsData]);

  useEffect(() => {
    getFirebaseRealTimeDatabase(userManager.entity, refresh);
  }, []);

  return (
    <Container className={Styles.container} maxWidth={false}>
      <Container className={Styles.content} maxWidth={false}>
        {!loadingProducts && (
        <div className={Styles.products}>
          {
                filterProducts().map((product) => (
                  <Product
                    key={product.id}
                    {...product}
                    isvariablePrice
                    onClick={() => handleClickProduct(product)}
                  />
                ))
              }
        </div>
        )}
      </Container>

      <BlockedModal
        message={message}
        open={openBlockedModal}
        onClose={close}
      />

    </Container>
  );
};
