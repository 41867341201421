import axios, {
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";

import {
  getLocalStorageToken,
} from "../services";
import { apiUrl } from "./envs";

const api: AxiosInstance = axios.create({
  baseURL: apiUrl,
});

const onRequest = (config: AxiosRequestConfig) => {
  const newConfig = config;

  if (newConfig.headers === undefined) {
    newConfig.headers = {};
  }

  const token = getLocalStorageToken();
  if (token) {
    newConfig.headers = {
      ...newConfig.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return newConfig;
};

api.interceptors.request.use(onRequest);

export default api;
