import { makeStyles, Theme } from "@material-ui/core/styles";

const categoriesHeight = 110;
const clientViewWidth = 320;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    padding: 0,
    overflow: "hidden",
    width: "100%",
    height: "100vh",
    paddingTop: categoriesHeight,
    paddingRight: clientViewWidth,
  },

  contentView: {
    padding: 0,
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
  },

  content: {},

  categories: {
    position: "fixed",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: `calc(100% - ${clientViewWidth}px)`,
    height: categoriesHeight,
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
  },

  clientView: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: clientViewWidth,
    paddingTop: 28,
    paddingRight: 36,
    paddingBottom: 36,
    paddingLeft: 25,
    backgroundColor: "#F5F5F5",
  },

  client: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 30,
  },

  clientInfo: {
    width: 190,

    "& span": {
      fontSize: 14,
      fontWeight: "bold",
      marginBottom: 18,
    },
  },

  clientPhoto: {
    width: 55,
    height: 55,
  },
}));

export default useStyles;
