import React from 'react';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@material-ui/core';
import { styles } from './styles';

type CurrencyInputType = {
    label?: any;
    value: any;
    onChange: any;
    styleVariablePrice?: boolean;
}

export const CurrencyInput: React.FC<CurrencyInputType> = ({
  label,
  value,
  onChange,
  styleVariablePrice = false,
}) => {
  const Styles = styles();
  const formatCurrency = (value3: any) => {
    if (!value3) return '';

    const amount = parseFloat(value3) / 100;
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    const formattedValue = formatter.format(amount);
    return formattedValue.substring(3);
  };

  const parseCurrency = (value1: any) => {
    const parsedValue = value1.replace(/\D/g, '');
    return parseInt(parsedValue, 10);
  };

  const handleChange = (event: any) => {
    const parsedValue = parseCurrency(event.target.value);
    onChange(parsedValue);
  };

  return (
    <div>
      <TextField
        className={Styles.input}
        label={label}
        variant="standard"
        InputProps={{
          startAdornment: <InputAdornment position="start"><p className="p">R$</p></InputAdornment>,
          style: styleVariablePrice
            ? {
              color: "#000",
              fontSize: 20,
              fontWeight: "bold",
              borderBottomStyle: "solid",
              borderBottomWidth: 2,
              borderBottomColor: "#000",
              zIndex: 9,
            }
            : {},
        }}
        value={formatCurrency(value)}
        onChange={handleChange}
      />
    </div>
  );
};
