import { Container, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { EditRequest, ArchiveTableRow } from "../../components";
import { getFirebaseRealTimeDatabase } from "../../services/firebaseRealTimeDatabase";
import { useStore } from "../../store";
import { KitchenOrder, Sector } from "../../types";
import useStyles from "./styles";
import { useFetchAllDailyOrders, useGetSectorsActive } from "../../api/orders/hooks";
import { Select } from "../../components/Select";
import { useEntity } from "../../hooks/useEntity";

export const Archive: React.FC = () => {
  const Styles = useStyles();

  const [entityId, setEntityId] = useState<string>("");
  const [orders, setOrders] = useState<any>([]);
  const [sectorsList, setSectorsList] = useState<any[]>([]);
  const [sectorId, setSectorId] = useState("all");
  const [selectedOrder, setSelectedOrder] = useState<KitchenOrder | null>(null);
  const [sectorSelected, setSectorSelected] = useState("all");

  const [openModal, setOpenModal] = useState<boolean>(true);

  const { userManager } = useStore();

  const { data: _orders, isLoading } = useFetchAllDailyOrders(entityId, true);
  const { data: sectors } = useGetSectorsActive(entityId, true);
  const { showSectors } = useEntity();

  const handleOpenModal = (order: KitchenOrder) => {
    setSelectedOrder(order);

    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

    setSelectedOrder(null);
  };

  const filterOrdersBySector = (order: any) => {
    if (sectorId === "all") return true;

    if (sectorId === "others") {
      return order.items?.some((item: any) => !item.segment);
    }

    return order.items?.some((item: any) => item.segment?.id === sectorId);
  };

  const loadOrders = async () => {
    if (_orders) {
      setOrders(
        _orders
          .sort(
            (firstOrder: any, secondOrder: any) =>
              new Date(secondOrder.createdAt).getTime() -
              new Date(firstOrder.createdAt).getTime()
          )
          .filter((order) => !order.isSchedule)
          .filter((order: any) => filterOrdersBySector(order))
      );
    }
  };

  const formatList = () => {
    const listSectors = sectors?.map((_sector: Sector) => ({
      label: _sector.name,
      value: _sector.id,
    }));

    const listSortedSectors: any[] = listSectors?.sort((a: any, b: any) =>
      a.label.localeCompare(b.label));

    listSortedSectors.unshift({ label: "Todos", value: "all" });
    listSortedSectors.push({ label: "Outros", value: "others" });

    setSectorsList(listSortedSectors);
  };

  const filterOrders = (id: string) => {
    setSectorSelected(id);
    setSectorId(id);
    loadOrders();
  };

  useEffect(() => {
    getFirebaseRealTimeDatabase(userManager.entity, loadOrders);
  }, []);

  useEffect(() => {
    (async () => {
      await loadOrders();
    })();
  }, [_orders, sectorId]);

  useEffect(() => {
    if (userManager.entity) {
      setEntityId(userManager.entity);
    }
  }, [userManager]);

  useEffect(() => {
    if (sectors?.length) {
      formatList();
    }
  }, [sectors]);

  return (
    <Container maxWidth={false} className={Styles.container}>
      <div className={Styles.table}>
        {sectorsList?.length > 0 && showSectors && (
          <>
            <div className={Styles.filterSector}>
              <Select
                label="Setor"
                options={sectorsList}
                value={sectorSelected}
                onChange={(val) => filterOrders(val)}
              />
            </div>
            <hr className={Styles.line} />
          </>
        )}
        <ArchiveTableRow header />
        {isLoading && (
          <div className={Styles.tableBody}>
            <div className={Styles.tableRow}>
              <Typography
                noWrap
                className={`${Styles.tableCell} ${Styles.tableColumn2}`}
              >
                Loading data...
              </Typography>
            </div>
          </div>
        )}
        {!isLoading && (
          <div className={Styles.tableBody}>
            {orders.map((order: any) => (
              <ArchiveTableRow
                key={order.id}
                data={order}
                selectOrder={() => handleOpenModal(order)}
              />
            ))}
          </div>
        )}
      </div>

      {selectedOrder && (
        <EditRequest
          order={selectedOrder}
          open={openModal}
          onClose={handleCloseModal}
          disableProductsActionButtons
        />
      )}
    </Container>
  );
};
