import { CircularProgress, Typography } from "@material-ui/core";

import {
  DeleteOutlineOutlined,
  CreateOutlined,
  CheckOutlined,
  LocalPrintshopOutlined,
} from "@material-ui/icons";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import React from "react";

import { ProfileIcon } from "../../assets";
import { KitchenOrder } from "../../types";

import useStyles from "./styles";

export interface OrderSchedule extends KitchenOrder {
  name: string;
  enrollment: string;
  orderAction: (id: string) => void;
  cancelOrder: (id: string) => void;
  selectOrder: () => void;
  onPrint: (id: string) => void;
  handleProfileClick: () => void;
  isLoading?: boolean;
  hasPrinter?: boolean;
}

export const CardOrderSchedule: React.FC<OrderSchedule> = ({
  id,
  scheduleFor,
  client,
  items,
  name,
  enrollment,
  isLoading,
  hasPrinter,
  orderAction,
  cancelOrder,
  selectOrder,
  handleProfileClick,
  onPrint,
}) => {
  const verifyEnrollment = () => {
    if (!enrollment) return null;

    return (
      <Typography component="span" className={Styles.ownerEnrollment} noWrap>
        {enrollment}
      </Typography>
    );
  };

  const Styles = useStyles({ client });

  return (
    <div className={Styles.card}>
      <div className={Styles.orderInfos}>
        <div className={Styles.orderTop}>
          <div className={Styles.orderOwner}>
            <div className={Styles.ownerPhoto}>
              {client?.picture ? (
                <div className={Styles.picture} onClick={handleProfileClick} />
              ) : (
                <ProfileIcon className={Styles.profileIcon} />
              )}
            </div>
            <div className={Styles.collunName}>
              <Typography component="span" className={Styles.ownerName} noWrap>
                {name}
              </Typography>
              {verifyEnrollment()}
            </div>
          </div>

          <div className={Styles.orderActions}>
            {hasPrinter ? (
              <div className={Styles.action} onClick={() => onPrint(id)}>
                <LocalPrintshopOutlined />
              </div>
            ) : (
              <div />
            )}

            <div className={Styles.action} onClick={() => cancelOrder(id)}>
              <DeleteOutlineOutlined />
            </div>

            <div className={Styles.action} onClick={selectOrder}>
              <CreateOutlined />
            </div>
          </div>

          {scheduleFor && (
            <div className={Styles.orderTime}>
              <Typography component="span">
                {format(new Date(scheduleFor), "dd/MM/yyyy HH:mm", { locale: ptBR })}
              </Typography>
            </div>
          )}
        </div>

        <div className={Styles.products}>
          {items
            .filter((_item) => _item.quantity > 0)
            .map((item) => (
              <div key={item.id} className={Styles.product}>
                <div className={Styles.amount}>
                  <Typography component="span">{item.quantity}</Typography>
                </div>

                <div className={Styles.name}>
                  <Typography component="span" noWrap>
                    {item.name}
                  </Typography>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className={Styles.orderAction} onClick={() => orderAction(id)}>
        {!isLoading ? <CheckOutlined /> : <CircularProgress color="inherit" />}
      </div>
    </div>
  );
};
