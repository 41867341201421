import { Modal, Typography } from "@material-ui/core";
import React, { useEffect } from "react";

import { KitchenIcon } from "../../../assets";
import { useStore } from "../../../store";

import { Button } from "../../index";

import useStyles from "./styles";

export interface FinishModalProps {
  open: boolean;
}

export const FinishModal: React.FC<FinishModalProps> = ({ open }) => {
  const Styles = useStyles();

  const { userTotem, navigation, order } = useStore();

  const oneSeconds = 1000;
  const twoSeconds = oneSeconds * 3;

  const handleExit = async () => {
    navigation.navigateTo("/");

    await userTotem.logout();
    await order.reset();
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => handleExit(), twoSeconds);
    }
  }, [open]);

  return (
    <Modal className={Styles.modal} open={open}>
      <div className={Styles.modalView}>
        <KitchenIcon />

        <Typography component="span" className={Styles.title}>
          Compra Finalizada!
        </Typography>

        <Typography component="span" className={Styles.message}>
          Aguarde e retire seu pedido na cantina.
        </Typography>

        <Button onClick={handleExit}>Sair</Button>
      </div>
    </Modal>
  );
};
