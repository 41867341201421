import { makeStyles } from "@material-ui/core/styles";

const marginTopList = 16;
const fontSizeTitle = 34;

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: 0,
    paddingTop: 24,
    overflowY: "hidden",
  },

  warpper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    padding: 0,
    paddingTop: 10,
    overflowY: "hidden",
  },

  orderList: {
    overflowY: "hidden",
    width: "50%",
    height: "100%",
  },

  titleView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  title: {
    fontSize: fontSizeTitle,
    fontWeight: "bold",
    marginLeft: 22,
    color: "#000",
  },

  list: {
    maxHeight: `calc(100% - ${marginTopList + fontSizeTitle}px)`,
    overflowX: "hidden",
    overflowY: "auto",
    marginTop: marginTopList,
    paddingBottom: 15,
    paddingRight: 28,
    marginLeft: 65,
  },

  filterSector: {
    marginTop: 40,
    marginBottom: 20,
  },

  filterSectorContainer: {
    marginTop: 125,
    marginBottom: 20,
  },

  line: {
    borderTop: 0.2,
    width: '100%',
    borderColor: '#C1C1C5',
  },

  placeHolder: {
    color: '#000000',
    fontSize: 12,
  }
}));

export default useStyles;
