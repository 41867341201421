import * as Yup from "yup";

export interface LoginAdministrativeSchemaInterface {
  password: string;
}

const requiredFieldMessage = "Campo Obrigatório";

export const LoginAdministrativeSchema: Yup.SchemaOf<LoginAdministrativeSchemaInterface> =
  Yup.object().shape({
    password: Yup.string().required(requiredFieldMessage),
  });
