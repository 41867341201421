import React from 'react';
import { useStyles } from './styles';

const CustomInput = ({
  label, value, onChange, labelColor = 'default', ...props
}: {
  label: string,
  value: any,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  labelColor?: 'primary' | 'secondary' | 'default',
  [key: string]: any
}) => {
  const classes = useStyles({ labelColor });

  return (
    <div className={classes.inputContainer}>
      <label htmlFor="customInput" className={classes.label}>{label}</label>
      <input
        id="customInput"
        className={classes.input}
        value={value}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};

export default CustomInput;
