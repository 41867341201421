import { useState, useMemo, useEffect } from "react";
import {
  cleanLocalStorageUserTotem,
  getLocalStorageUserTotem,
  setLocalStorageUserTotem,
  logoutClient,
} from "../../services";

import { Totem, VirtualCard, Wallet } from "../../types";
import { useUserManager } from "./userManager";

export const walletDefault: Wallet = {
  period: "daily",
  periodLimit: 0,
  spent: 0,
};

export interface UserTotemDefault {
  login: (user: Totem) => void;
  logout: (go?: boolean) => void;
  updateWallet: (wallet: Wallet) => void;
}

export type UserTotemStore = Totem & UserTotemDefault;

export function useUserTotem() {
  const [id, setId] = useState<string>("");
  const [identityNumber, setIdentityNumber] = useState<string>("");
  const [picture, setPicture] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [paymentInfos, setPaymentInfo] = useState<Array<string> | null>(null!);
  const [email, setEmail] = useState<string>("");
  const [virtualCard, setVirtualCard] = useState<VirtualCard | null>(null);
  const [wallet, setWallet] = useState<Wallet>(walletDefault);
  const [userId, setUserId] = useState<string>("");

  const { id: totem, entity } = useUserManager();

  const login = (user: Totem) => {
    const _wallet = {
      period: user.wallet.period,
      periodLimit: user.wallet.periodLimit / 100,
      spent: user.wallet.spent / 100,
    };

    setId(user.id);
    setIdentityNumber(user.identityNumber);
    setPicture(user.picture);
    setName(user.name);
    setVirtualCard(user.virtualCard);
    setWallet(_wallet);
    setUserId(user.userId);
    setRole(user.role);

    if (user.role === "adminClient") {
      setPaymentInfo(user.paymentInfos);
      if (user.email) {
        setEmail(user.email);
      }
    }

    if (!user.paymentInfos) {
      setPaymentInfo([]);
    }

    setLocalStorageUserTotem({
      ...user,
      wallet: _wallet
    });
  };

  const logout = async (go?: boolean) => {
    await logoutClient(totem, entity);

    setId("");
    setIdentityNumber("");
    setPicture("");
    setName("");
    setVirtualCard(null);
    setWallet(walletDefault);
    setUserId("");
    setRole("");

    cleanLocalStorageUserTotem();

    if (!go) {
      window.location.replace("/");
    }
  };

  const updateWallet = (_wallet: Wallet) => {
    const currentWallet = {
      period: _wallet.period,
      periodLimit: _wallet.periodLimit / 100,
      spent: _wallet.spent / 100,
    };

    setWallet(currentWallet);
  };

  useEffect(() => {
    const _userTotem = getLocalStorageUserTotem();

    if (_userTotem) {
      login(_userTotem);
    }
  }, []);

  return useMemo(
    () => ({
      id,
      identityNumber,
      picture,
      name,
      virtualCard,
      wallet,
      userId,
      login,
      logout,
      updateWallet,
      role,
      paymentInfos,
      email,
    }),
    [id, identityNumber, picture, name, virtualCard, wallet, userId, login, logout, updateWallet, role, paymentInfos, email]
  );
}
