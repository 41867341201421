import api from "../configs/api";

import { Product, ProductArray } from "../types";

export const getProducts = async (
  entity: string,
  client?: string
): Promise<ProductArray> => {
  const { status: statusProducts, data: products } = await api.get(
    `/products/entity/${entity}/active`
  );

  if (statusProducts !== 200) throw new Error();

  if (client) {
    const { status: statusSelectedProducts, data: selectedProducts } =
      await api.get(`/products/clients/${client}`);

    if (statusSelectedProducts !== 200) throw new Error();

    const newProducts = products.map((product: Product) => {
      const newProduct = product;

      const selectedProduct = selectedProducts.find(
        (_selectedProduct: any) => _selectedProduct.product.id === product.id
      );

      newProduct.isLocked = selectedProduct?.locked ?? false;
      newProduct.isHighlighted = newProduct.isHighlighted ?? false;

      return newProduct;
    }) as ProductArray;

    return newProducts;
  }

  return products;
};
