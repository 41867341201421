import { makeStyles, Theme } from "@material-ui/core/styles";

import { ButtonProps } from "./index";

type DynamicStylesProps = Partial<ButtonProps>;

const getFontSize = (size: DynamicStylesProps["size"]) => {
  switch (size) {
    case "large":
      return 16;

    case "medium":
      return 14;

    case "small":
      return 12;

    default:
      return 14;
  }
};

const getHeight = (size: DynamicStylesProps["size"]) => {
  switch (size) {
    case "large":
      return 56;

    case "medium":
      return 48;

    case "small":
      return 36;

    default:
      return 48;
  }
};

const getBackgroundColor = (
  color: DynamicStylesProps["color"],
  primary: string,
  secondary: string
) => {
  switch (color) {
    case "primary":
      return primary;

    case "secondary":
      return secondary;

    default:
      return primary;
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: ({ fullWidth }: DynamicStylesProps) => (fullWidth ? 345 : 165),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
    color: ({ color }: DynamicStylesProps) =>
      theme.palette.getContrastText(
        getBackgroundColor(
          color,
          theme.palette.primary.main,
          theme.palette.secondary.main
        )
      ),
    fontWeight: "bold",
    transition: "all .2s",

    fontSize: ({ size }: DynamicStylesProps) => getFontSize(size),

    height: ({ size }: DynamicStylesProps) => getHeight(size),

    backgroundColor: ({ color }: DynamicStylesProps) =>
      getBackgroundColor(
        color,
        theme.palette.primary.main,
        theme.palette.secondary.main
      ),

    "&:active": {
      opacity: 0.8,
    },
  },

  disabled: {
    backgroundColor: "#F9F9F9 !important",
    color: "#A9A9AD !important",
    cursor: "default",
  },
}));

export default useStyles;
