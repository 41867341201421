import api from "../configs/api";
import { Totem, Wallet, Client } from "../types";

export const getWalletByClient = async (client: string): Promise<Wallet | false> => {
  try {
    const payload = {
      clientId: client,
      localDate: new Date().toString(),
    };

    const { status, data } = await api.post('/client-order/client/balance/period', payload);

    if (status !== 200) throw new Error();

    return data as Wallet;
  } catch (error) {
    return false;
  }
};

export const getClientById = async (id: string): Promise<Totem | false> => {
  try {
    const { status, data } = await api.get(`/clients/field/id/value/${id}`);

    if (status !== 200) throw new Error();

    return data as Totem;
  } catch (error) {
    return false;
  }
};

export const getTotemClients = async (entityID: string): Promise<Client[] | false> => {
  try {
    const { status, data } = await api.get(`/totem/entity/${entityID}/clients`);

    if (status !== 200) throw new Error();

    return data as Client[];
  } catch (error) {
    return false;
  }
};
