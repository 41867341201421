import * as Yup from "yup";

export interface StudentSchemaInterface {
  name: string;
}

const requiredFieldMessage = "Campo Obrigatório";

export const StudentSchema: Yup.SchemaOf<StudentSchemaInterface> =
  Yup.object().shape({
    name: Yup.string().required(requiredFieldMessage),
  });
