import { Period } from "../types";

export const getPeriodLabel = (period: Period) => {
  switch (period) {
    case "daily":
      return "Dia";
    case "weeklySpendLimit":
      return "Semana";
    case "monthlySpendLimit":
      return "Mês";
    case "sunday":
      return "Domingo";
    case "monday":
      return "Segunda";
    case "tuesday":
      return "Terça";
    case "wednesday":
      return "Quarta";
    case "thursday":
      return "Quinta";
    case "friday":
      return "Sexta";
    case "saturday":
      return "Sábado";
    default:
      return "Total";
  }
};
