import { makeStyles, Theme } from "@material-ui/core/styles";
import KitchenBg from "../../../assets/images/kitchen-bg.jpg";
import { getBackgroundColor, getBackgroundColorForFontColor, Route } from "../../../utils/kitchenBackGroundColor";

const lateralMenuWidth = 65;
const appBarHeight = 76;

const paddingVerticalAppBar = 40;

const paddingContent = 42;

interface DynamicStylesProps {
  pathname: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    overflow: "hidden",
    width: "100%",
    height: "100vh",
    backgroundColor: ({ pathname }: DynamicStylesProps) => getBackgroundColor(pathname as Route),
    backgroundImage: ({ pathname }: DynamicStylesProps) => pathname === 'requests' || pathname === 'confirm-manual-purchase' ? `url(${KitchenBg})` : "none",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundClip: "border-box",
  },

  openDrawerButton: {
    flex: 1,
    padding: 0,
  },

  lateralMenu: {
    zIndex: 3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: lateralMenuWidth,
    height: "100vh",
    padding: 0,
    paddingTop: 18,
    paddingBottom: 18,
    margin: 0,
    backgroundColor: theme.palette.background.paper,
    borderRightWidth: ({ pathname }: DynamicStylesProps) => pathname === 'variable-price-purchase' || pathname === 'product-variable-price-purchase' ? `0.1` : "none",
    borderRightStyle: ({ pathname }: DynamicStylesProps) => pathname === 'variable-price-purchase' || pathname === 'product-variable-price-purchase' ? `solid` : "none",
    borderRightColor: ({ pathname }: DynamicStylesProps) => pathname === 'variable-price-purchase' || pathname === 'product-variable-price-purchase' ? `#E5E5E8` : "none",
  },

  logout: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    flex: 1,
  },

  appBar: {
    position: "fixed",
    top: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: `calc(100% - ${lateralMenuWidth}px)`,
    height: appBarHeight,
    paddingRight: paddingVerticalAppBar,
    paddingLeft: paddingVerticalAppBar,
  },

  label: {
    fontSize: 24,
    fontWeight: "bold",
    color: ({ pathname }: DynamicStylesProps) => theme.palette.getContrastText(getBackgroundColorForFontColor(pathname as Route)),
  },

  subTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: ({ pathname }: DynamicStylesProps) => theme.palette.getContrastText(getBackgroundColorForFontColor(pathname as Route)),
  },

  labelSubTitlecontainer: {
    width: "87%",
    display: "flex",
    flexDirection: "column",
  },

  clock: {
    fontSize: 16,
    fontWeight: "bold",
    color: ({ pathname }: DynamicStylesProps) => theme.palette.getContrastText(getBackgroundColorForFontColor(pathname as Route)),
  },

  closeDrawerButton: {
    position: "absolute",
    left: 0,
    top: 0,
    display: "flex",
    alignItems: "center",
    padding: 0,
    paddingLeft: 5,
    color: "#FFF",
  },

  contentView: {
    width: `calc(100% - ${lateralMenuWidth}px)`,
    height: "100%",
    overflow: "hidden",
    padding: 0,
  },

  content: {
    height: "100%",
    padding: paddingContent,
    paddingTop: 0,
    paddingBottom: 0,
    overflow: "hidden",
  },

  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: 42,
    height: 42,
    marginTop: 8,
    color: theme.palette.primary.main,
    borderRadius: 9,

    transition: "all .35s",
  },

  selected: {
    backgroundColor: "#E5E5E8",
  },

  icon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  itemIcon: {
    justifyContent: "center",
    height: 18,
  },

  text: {
    flex: 1,
    color: theme.palette.background.default,
  },

  itemText: {
    fontSize: 16,
  },
}));

export default useStyles;
