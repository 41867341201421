import React from "react";

import useStyles from "./styles";

export interface ProgressProps {
  percentage: number;
}

export const Progress: React.FC<ProgressProps> = ({ percentage }) => {
  const Styles = useStyles({ percentage });

  return (
    <div className={Styles.progressView}>
      <div className={Styles.progress} />
    </div>
  );
};
