import { makeStyles, Theme } from "@material-ui/core/styles";

const categoriesHeight = 110;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    overflow: "hidden",
    width: "100%",
    height: "100vh",
    paddingTop: categoriesHeight,
  },

  content: {
    width: '100%',
    paddingTop: 50,
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
  },

  products: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: "45px 25px",
  },

  subTitle: {
    paddingLeft: 20,
    paddingBottom: 20,
    marginTop: -35,
    fontSize: 18,
    fontWeight: "bold",
  }

}));

export default useStyles;
