import React, { useEffect } from "react";

import jwtDecode, { JwtPayload } from "jwt-decode";

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { KitchenLayout, TotemLayout, PublicLayout } from "./components";
import {
  publicRoutes,
  RouteInterface,
  getPrivateRoutes,
  validPathByUserRole,
  Path,
} from "./configs/routes";

import { StateProvider, useStore } from "./store";

import { RoleType } from "./types";

export default () => (
  <BrowserRouter>
    <StateProvider>
      <Layout />
    </StateProvider>
  </BrowserRouter>
);

const Layout: React.FC = () => {
  const { userManager, navigation } = useStore();

  useEffect(() => {
    if (userManager.token) {
      const tokenDecoded = jwtDecode<JwtPayload>(userManager.token);
      const currentTime = Date.now();

      const countDown = (tokenDecoded.exp ?? 0) * 1000 - currentTime;

      setTimeout(() => userManager.callRefreshToken(), countDown - 1000);
    }
    if (!validPathByUserRole(userManager.role, window.location.pathname as Path)) {
      navigation.navigateTo("/");
    }
  }, [navigation.pathname, userManager.role, window.location.pathname]);

  useEffect(() => {
    if (userManager.token) {
      const tokenDecoded = jwtDecode<JwtPayload>(userManager.token);
      const currentTime = Date.now();

      const countDown = (tokenDecoded.exp ?? 0) * 1000 - currentTime;

      setTimeout(() => userManager.callRefreshToken(), countDown);
    }
  }, [userManager.token]);

  return userManager.role && userManager.entity ? (
    getLayoutByUserRole(userManager.role)
  ) : (
    <PublicLayout>
      <Routes>
        {publicRoutes.map(({ path, Page }: RouteInterface) => (
          <Route key={path} path={path} element={<Page />} />
        ))}
      </Routes>
    </PublicLayout>
  );
};

const getLayoutByUserRole = (role: RoleType) => {
  switch (role) {
    case "kitchen":
      return (
        <KitchenLayout>
          <Routes>{getRoutesByUserRole(role)}</Routes>
        </KitchenLayout>
      );
    case "totem":
      return (
        <TotemLayout>
          <Routes>{getRoutesByUserRole(role)}</Routes>
        </TotemLayout>
      );
    default:
      return (
        <TotemLayout>
          <Routes>{getRoutesByUserRole(role)}</Routes>
        </TotemLayout>
      );
  }
};

const getRoutesByUserRole = (role: RoleType) =>
  getPrivateRoutes(role).map(({
    path, redirect, to, Page
  }: RouteInterface) =>
    redirect ? (
      <Route key={path} path={path} element={<Navigate to={to} />} />
    ) : (
      <Route key={path} path={path} element={<Page />} />
    ));
