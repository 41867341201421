import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  purchase: {
    flex: 1,
    width: "100%",
  },

  content: {
    marginTop: 8,
  },

  view: {
    overflowY: "hidden",
  },

  items: {
    width: "100%",
    maxHeight: 435,
    paddingTop: 24,
    paddingRight: 36,
    paddingBottom: 24,
    paddingLeft: 25,
    marginBottom: 8,
    overflowY: "auto",
  },

  divider: {
    width: "100%",
    height: 2,
    backgroundColor: "#E8E8EA",
  },

  total: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 24,
    paddingRight: 36,
    paddingLeft: 25,

    "& span": {
      fontSize: 16,
      fontWeight: "bold",
    },
  },

  consumption: {
    width: "100%",
  },

  virtualCard: {
    width: "100%",
    paddingTop: 24,
    paddingRight: 36,
    paddingBottom: 24,
    paddingLeft: 25,
  },

  balance: {},

  balanceValueLabels: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 600,
  },

  progressLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingTop: 6,
  },

  label: {
    fontSize: 12,
    fontWeight: 600,
  },

  remainingBalance: {
    display: "flex",
    flexDirection: "column",
    marginTop: 22,
    paddingBottom: 40,
    borderBottom: "2px solid #E5E5E8",

    "& span": {
      marginTop: 6,
    },
  },

  extract: {
    flex: 1,
    marginTop: 18,
  },

  extractTitle: {
    paddingRight: 36,
    paddingLeft: 25,
  },

  extractItems: {
    overflowY: "auto",
    height: "100%",
    marginTop: 10,
    paddingBottom: 10,
  },

  extractItemView: {
    paddingRight: 36,
    paddingLeft: 25,
  },

  extractItemViewTotal: {
    paddingRight: 36,
    paddingLeft: 25,
    marginTop: 40,
    marginBottom: 20,
  },

  extractItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  extractInfo: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  extractInfoMargin: {
    marginBottom: 30,
  },

  extractLabel: {
    fontSize: 12,
    fontWeight: 600,
  },

  sectorTitle: {
    fontSize: 14,
    fontWeight: 500,
  },
  spacer10: {
    height: 10,
  },
  spacer20: {
    height: 20,
  },
}));

export default useStyles;
