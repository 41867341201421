import { Typography } from "@material-ui/core";
import React from "react";

import { Sector as SectorInterface } from "../../types";

import useStyles from "./styles";

interface SectorDefault {
  selected?: boolean;
  onClick?: (id: string) => void;
}

export type SectorProps = SectorInterface & SectorDefault;

export const Sector: React.FC<SectorProps> = ({
  id,
  name,
  selected = false,
  onClick,
}) => {
  const Styles = useStyles({ selected });

  return (
    <div className={Styles.sectorContainer} onClick={() => onClick && onClick(id)}>
      <Typography component="span" className={Styles.name} noWrap>
        {name}
      </Typography>
    </div>
  );
};
