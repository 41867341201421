import { makeStyles } from "@material-ui/core/styles";

interface DynamicStylesProps {
  picture: string;
}

const useStyles = makeStyles(() => ({
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 12,
  },

  info: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  picture: {
    position: "relative",
    width: 46,
    height: 46,
    borderRadius: 14,
    backgroundImage: ({ picture }: DynamicStylesProps) => `url(${picture})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundClip: "border-box",
    boxShadow:
      "0px 8px 12px rgba(0, 0, 0, 0.08), 0px 4px 48px rgba(0, 0, 0, 0.08)",
  },

  quantity: {
    position: "absolute",
    right: -5,
    bottom: -5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 16,
    height: 16,
    borderRadius: 16,
    backgroundColor: "#000",
    color: "#FFF",

    "& span": {
      fontSize: 8,
      fontWeight: 600,
      lineHeight: 0,
    },
  },

  name: {
    flex: 1,
    width: 118,
    fontSize: 12,
    fontWeight: 600,
    marginLeft: 14,
  },

  price: {
    fontSize: 12,
    fontWeight: 600,
    marginLeft: 14,
  },

  close: {
    width: 16,
    height: 16,
    marginLeft: 8,
  },
}));

export default useStyles;
