export default function sumValuesFromAnArray(array: number[]): number {
  let total = 0;
  for (let i = 0; i < array.length; i++) {
    if (array[i]) {
      const value = array[i];

      total += isNaN(value) ? 0 : Number(value);
    }
  }
  return total;
}
