import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    minHeight: "60vh",
    height: "100%",
    width: "100%",
    position: "relative",
    overflow: "hidden",
  },
  containerWebCam: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    justifyContent: "center",
    minHeight: 500
  },
  containerPhoto: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  buttonCamera: {
    backgroundColor: "black",
    borderRadius: "50%",
    padding: 10,
    cursor: "pointer",
    display: "flex",
    width: 70,
    height: 70,
    position: "absolute",
    bottom: -35,
  },

  buttonReset: {
    backgroundColor: "black",
    borderRadius: "50%",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    padding: 10,
    cursor: "pointer",
    display: "flex",
    width: 70,
    height: 70,
    bottom: 85
  },
  photo: {
    width: "100%",
  },

  buttonLogin: {
    width: "fit-content",
    marginLeft: "auto",
    marginTop: 10
  },

  webCam: {
    height: 500,
    width: "100%",
  },
  icon: {
    fontSize: 50, color: 'white'
  },

  containerLoading: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
  },

  iframe: {
    position: 'absolute',
    border: 'none',
    width: '100%',
    height: '100%',
  }
}));
export default useStyles;
