import { makeStyles } from "@material-ui/core/styles";

const appBarHeight = 76;

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    padding: 0,
    paddingTop: appBarHeight,
    overflowY: "hidden",
  },

  table: {
    display: 'flex',
    flexDirection: 'column',
    width: "100%",
    height: "100%",
  },

  tableBody: {
    flex: 1,
    width: "100%",
    overflowY: "auto",
  },

  tableCell: {
    fontSize: 12,
    fontWeight: 600,
  },

  tableColumn2: {
    width: 304,
    paddingRight: 10,
  },

  tableRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 45,
    paddingLeft: 28,
    paddingRight: 20,
    marginBottom: 10,
    borderRadius: 14,
    backgroundColor: "#FFF",
  },

  filterSector: {
    marginTop: 40,
    marginBottom: 20,
  },

  selectsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 20,
    alignItems: "center"
  },

  line: {
    borderTop: 0.2,
    width: '100%',
    borderColor: '#C1C1C5'
  },

  placeHolder: {
    color: '#000000',
    fontSize: 12,
  },

  filterWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 20,
    marginTop: 40,
    gap: 10,
    width: '100%',
    marginBottom: 20,
    alignItems: "center"
  },

  tableWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    padding: 0,
    overflow: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },

  alphabetIndex: {
    position: 'fixed',
    top: appBarHeight + 150,
    left: 1000,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    width: '10px',
    height: 'auto',
    marginRight: 'calc(100% - 600px)',
  },

  alphabetItem: {
    padding: '4px 0',
    fontSize: 12,
    fontWeight: 600,
    color: '#333',
  },

  alphabetItemUnavailable: {
    color: '#C1C1C5',
    cursor: 'not-allowed',
  },

}));

export default useStyles;
