import {
  Avatar, ListItem, ListItemAvatar, ListItemText
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ListItemButton } from '@mui/material';
import { useStyles } from './styles';
import { UserListingSearch } from './UserListingSearch';

interface IUserListingProps {
  UserList: {
    id: string;
    name: string;
    email: string;
    picture: string;
  }[];
  hidden?: boolean;
  setSelectedClient?: any;
  selectedClient?: any;
}

const UserListing: React.FC<IUserListingProps> = ({
  UserList, hidden, setSelectedClient, selectedClient
}) => {
  const Styles = useStyles();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(UserList);

  const [onSearchFocus, setOnSearchFocus] = useState(false);

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredUsers(UserList);
    } else {
      setFilteredUsers(
        UserList.filter(
          (user) => user.name.toLowerCase().startsWith(searchQuery.toLowerCase())
        )
      );
    }
  }, [searchQuery, UserList]);

  const matchSelectedClient = (user: any) => {
    if (selectedClient?.id === user.id) {
      return Styles.listingItemSelected;
    }
    return "";
  };

  useEffect(() => {
    if (selectedClient && !onSearchFocus) {
      setFilteredUsers([selectedClient]);
      setSearchQuery(selectedClient.name);
    } else if (selectedClient && onSearchFocus) {
      setFilteredUsers(UserList);
      setSearchQuery("");
      setSelectedClient(null);
    }
  }, [selectedClient, onSearchFocus]);

  return (
    <div hidden={hidden}>
      <ListItem className={Styles.listingContainer}>
        <UserListingSearch
          hidden={hidden}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          setOnSearchFocus={setOnSearchFocus}
        />
        <div className={Styles.itemContainer}>
          {filteredUsers.map((user) => (
            <ListItemButton
              key={user?.id}
              onClick={
              () => setSelectedClient(user)
            }
            >
              <div className={
                matchSelectedClient(user) ? Styles.listingItemSelected : Styles.listingItem
              }
              >
                <ListItemAvatar>
                  <Avatar alt={user.name} src={user.picture} />
                </ListItemAvatar>
                <ListItemText primary={user.name} />
              </div>
            </ListItemButton>
          ))}
        </div>
      </ListItem>
    </div>
  );
};

export { UserListing };
