import React from "react";

import { Typography } from "@material-ui/core";

import { StarIcon, LockIcon } from "../../assets";

import { Product as ProductInterface } from "../../types";

import useStyles from "./styles";

interface ProductDefault {
  isPriceWithoutDiscount?: boolean;
  onClick?: () => void;
}

export type ProductProps = ProductInterface & ProductDefault;

export const Product: React.FC<ProductProps> = ({
  name,
  picture,
  price,
  isLocked,
  priceWithoutDiscount,
  isPriceWithoutDiscount,
  isvariablePrice = false,
  onClick,
}) => {
  const Styles = useStyles({ picture, isLocked });

  const formatMoney = (value: number): string =>
    Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
      value
    );

  return (
    <div className={Styles.product} onClick={() => onClick && onClick()}>
      {isLocked && (
        <div className={Styles.blocked}>
          <LockIcon />
        </div>
      )}

      {picture ? (
        <div className={Styles.image} />
      ) : (
        <div className={Styles.imageEmpty}>
          <StarIcon />
        </div>
      )}

      <div className={Styles.info}>
        <Typography component="span" className={Styles.name}>
          {name}
        </Typography>

        {isvariablePrice ? (
          <Typography component="span" className={Styles.price}>
            Preço variável
          </Typography>
        ) : (
          <div className={Styles.containerDiscount}>
            {isPriceWithoutDiscount && priceWithoutDiscount && (
              <Typography
                component="span"
                className={Styles.priceDiscount}
                style={{ textDecoration: "line-through" }}
              >
                {formatMoney(priceWithoutDiscount / 100)}
              </Typography>
            )}
            <Typography component="span" className={Styles.price}>
              {formatMoney(price / 100)}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
