import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import QRCode from "react-qr-code";
import {
  CircularProgress, Typography
} from "@material-ui/core";
import useStyles from "./styles";
import { Button } from "../../components";
import { useStore } from "../../store";
import { LoadingModal } from "../../components";
import CustomSelect from "./CardSelect";
import { createOrderIndividual } from "../../services";
import { useCreatePaymentVisitor, useGetTransaction } from "../../api/payment/hooks";
import { CreatePaymentVisitorResponse } from "../../types";

export const ConfirmIndividualPurchase: React.FC = () => {
  const {
    state,
  }: any = useLocation();

  switch (state?.type) {
    case "pix":
      return <PixPurchase />;

    default:
      return <CreditPurchase />;
  }
};

const CreditPurchase: React.FC = () => {
  const Styles = useStyles();

  const {
    userTotem, order, navigation, userManager
  } = useStore();

  const [selectedCard, setSelectedCard] = React.useState('');

  const [modalMessage, setModalMessage] = React.useState('');

  const formatCardNumber = (cardNumber: string) => `**** **** **** ${cardNumber}`;

  const handleBack = async () => {
    navigation.navigateTo("/individual-purchase");
  };

  const onSubmit = async () => {
    // credit card payload

    const payload = {
      entityId: userManager.entity,
      paymentMethod: "2",
      isSelfCheckoutTotem: false,
      adminClient: userTotem.id,
      amount: order.totalAmount,
      paymentInfoId: selectedCard,
      items: order.items.map((item) => {
        const {
          id,
          name,
          amount,
          totalAmount,
          price,
        } = item;

        return {
          id,
          name,
          amount,
          quantity: amount,
          price,
          totalAmount,
        };
      }),
      clientName: userTotem.name,
      isOnCredit: true,
      isIndividual: true,
    };

    const res = await createOrderIndividual(payload);

    order.reset();

    if (res) {
      setModalMessage("Compra Finalizada");

      setTimeout(() => {
        navigation.navigateTo("/individual-purchase");

        setModalMessage("");
      }, 3000);
    } else {
      setModalMessage("Falha ao realizar seu pedido");

      setTimeout(() => {
        navigation.navigateTo("/individual-purchase");

        setModalMessage("");
      }, 3000);
    }
  };

  const handleFinishPurchase = async () => {
    if (selectedCard) {
      await onSubmit();
    }
  };

  const cardOptions = userTotem.paymentInfos?.map((card) => ({
    id: card.id,
    value: card.cardLast4Numbers.toString(),
    label: `Cartão: ${formatCardNumber(card.cardLast4Numbers)}`,
  })) || [];

  return (
    <div className={Styles.container}>
      <div className={Styles.formView}>
        <Typography className={Styles.title}>
          {`Pagamento Crédito - ${userTotem.name}`}
        </Typography>
        <CustomSelect options={cardOptions} value={selectedCard} onChange={setSelectedCard} />
        <div className={Styles.actions}>
          <Button
            color={selectedCard ? "primary" : "secondary"}
            disabled={!selectedCard}
            size="large"
            fullWidth
            onClick={() => handleFinishPurchase()}
          >
            Finalizar Compra
          </Button>
          <div className={Styles.spacer18} />

          <Button
            color="secondary"
            size="large"
            fullWidth
            onClick={() => handleBack()}
          >
            Voltar
          </Button>

          <LoadingModal
            open={!!modalMessage}
            message={modalMessage}
          />
        </div>
      </div>
    </div>
  );
};

const PixPurchase: React.FC = () => {
  const Styles = useStyles();

  const {
    userManager,
    navigation,
    userTotem,
    order,
  } = useStore();

  const [pixCode, setPixCode] = useState<string>("");

  const [transactionId, setTransactionId] = useState<string>('0');

  const [isActive, setIsActive] = useState(false);

  const [seconds, setSeconds] = useState<number>(300);

  const [modalMessage, setModalMessage] = useState<string>("");

  const [reTryPaymentCall, setReTryPaymentCall] = useState(false);

  const { data: transaction, refetch: refetchTransaction } = useGetTransaction(
    transactionId,
    !!transactionId,
  );

  const { mutateAsync, isLoading } = useCreatePaymentVisitor();

  const formatTime = () => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    return `${minutes}:${secondsLeft < 10 ? '0' : ''}${secondsLeft}`;
  };

  let intervalId: any = null;

  useEffect(() => {
    const makeApiCall = async () => {
      if (transactionId) {
        if (isActive && seconds !== 0) await refetchTransaction();
      }
    };

    intervalId = setInterval(makeApiCall, 1500);

    return () => clearInterval(intervalId);
  }, [transactionId]);

  useEffect(() => {
    let interval: any = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((s: number) => s - 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  useEffect(() => {
    (async () => {
      if (transaction) {
        if (transaction.status === '3') {
          setIsActive(false);

          order.reset();

          setModalMessage("Compra Finalizada");

          setTimeout(() => {
            navigation.navigateTo('/login-user');

            setModalMessage("");
          }, 3000);
        }
      }
    })();
  }, [transaction]);

  useEffect(() => {
    (async () => {
      const response: CreatePaymentVisitorResponse = await mutateAsync({
        entityId: userManager.entity,
        paymentMethod: "6",
        isSelfCheckoutTotem: false,
        adminClient: userTotem.id,
        amount: order.totalAmount,
        items: order.items.map((item) => {
          const {
            id,
            name,
            amount,
            totalAmount,
            price,
          } = item;

          return {
            id,
            name,
            amount,
            quantity: amount,
            price,
            totalAmount,
          };
        }),
        clientName: userTotem.name,
        isOnCredit: false,
        isIndividual: true,
      });

      setTransactionId(response?.id);
      setPixCode(response?.qrCodeKey);
      setIsActive(true);
      setSeconds(300);
    })();
  }, [reTryPaymentCall]);

  const handleBack = () => {
    navigation.navigateTo("/");
  };

  const resetCountdown = (interval: any) => {
    clearInterval(interval);
    setReTryPaymentCall(!reTryPaymentCall);
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.containerRelative}>
        <div className={Styles.formView}>
          <Typography className={Styles.title}>
            {`Pagamento Pix - ${userTotem.name}`}
          </Typography>

          <div className={Styles.PixContainer}>
            {
                isLoading ? (
                  <CircularProgress />
                ) : pixCode && (
                  <QRCode value={pixCode} size={350} />
                )
              }
          </div>

          <div className={Styles.actions}>
            <Typography className={Styles.subTitle}>
              {`Gerar Novamente em ${formatTime()}`}
            </Typography>
            <Button
              size="large"
              fullWidth
              disabled={seconds !== 0}
              onClick={resetCountdown}
            >
              Gerar Novamente
            </Button>
            <div className={Styles.spacer18} />

            <Button
              color="secondary"
              size="large"
              fullWidth
              onClick={() => handleBack()}
            >
              Voltar
            </Button>

          </div>
        </div>

        <LoadingModal
          open={!!modalMessage}
          message={modalMessage}
        />

      </div>
    </div>
  );
};
