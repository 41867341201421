const localStorageBaseKey = "MenuPass_Totem";

export type LocalStorageBaseKey =
  "User" |
  "UserToken" |
  "UserRefreshToken" |
  "UserManager" |
  "UserTotem";

export const setLocalStorage = (
  localStorageKey: LocalStorageBaseKey,
  data: any
) => {
  localStorage.setItem(
    `${localStorageBaseKey}@${localStorageKey}`,
    JSON.stringify(data)
  );
};

export const getLocalStorage = (
  localStorageKey: LocalStorageBaseKey
): any | null => {
  const data = localStorage.getItem(
    `${localStorageBaseKey}@${localStorageKey}`
  );

  return data ? JSON.parse(data) : null;
};

export const deleteLocalStorage = (localStorageKey: LocalStorageBaseKey) => {
  localStorage.removeItem(`${localStorageBaseKey}@${localStorageKey}`);
};
