import { makeStyles, Theme } from "@material-ui/core/styles";

import { CategoryProps } from "./index";

type DynamicStylesProps = Partial<CategoryProps>;

const useStyles = makeStyles((theme: Theme) => ({
  category: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    width: "auto",
    minWidth: 165,
    height: "90%",
  },

  imageEmpty: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 55,
    minHeight: 55,
    marginRight: 18,
    borderRadius: theme.shape.borderRadius,
    boxShadow:
      "0px 8px 12px rgba(0, 0, 0, 0.08), 0px 4px 48px rgba(0, 0, 0, 0.08)",
    backgroundColor: "#000",
  },

  image: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 55,
    minHeight: 55,
    marginRight: 18,
    borderRadius: theme.shape.borderRadius,
    backgroundImage: ({ picture }: DynamicStylesProps) => `url(${picture})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundClip: "border-box",
    boxShadow:
      "0px 8px 12px rgba(0, 0, 0, 0.08), 0px 4px 48px rgba(0, 0, 0, 0.08)",
  },

  name: {
    fontSize: 14,
    fontWeight: "normal",
  },

  selected: {
    position: "absolute",
    right: 0,
    bottom: 0,
    left: 0,
    width: "100%",
    height: 3,
    backgroundColor: "#000",
  },
}));

export default useStyles;
