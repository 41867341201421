import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  filterWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    width: '100%',
    paddingLeft: 10,
    marginTop: 100,
  },
  label: {
    fontWeight: 600,
    marginRight: 10,
    fontSize: 14,
    marginBottom: 5,
    color: (props: { labelColor: 'primary' | 'secondary' | 'default' }) => {
      switch (props.labelColor) {
        case 'primary':
          return '#000000';
        case 'secondary':
          return '#ffffff';
        default:
          return '#000000';
      }
    },
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
  },
  input: {
    padding: '10px',
    border: '1px solid #C1C1C5',
    borderRadius: 4,
    fontSize: 14,
    backgroundColor: '#FFF',
  },
}));
