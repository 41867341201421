import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import {
  apiKey, appId, authDomain, storageBucket, projectId, messagingSenderId, databaseURL
} from "./envs";

const firebaseConfig = {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
  appId
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
