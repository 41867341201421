import { Typography } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import { format } from "date-fns";
import React from "react";
import { KitchenOrder } from "../../types";
import { formatCurrency } from "../../utils/format";
import { getClientNameForOrder } from "../../utils/getClientNameForOrder";
import useStyles from "./styles";

export interface ArchiveTableRow {
  selectOrder: () => void;
  header?: false;
  data: KitchenOrder;
}

export interface ArchiveTableRowHeader {
  header: true;
  selectOrder?: never;
  data?: never;
}

export type ArchiveTableRowProps = ArchiveTableRowHeader | ArchiveTableRow;

export const ArchiveTableRow: React.FC<ArchiveTableRowProps> = ({
  header = false,
  data,
  selectOrder
}) => {
  const Styles = useStyles();

  return header && !data ? (
    <div className={Styles.tableHeader}>
      <Typography className={`${Styles.tableHeaderCell} ${Styles.tableColumn1}`}>Pedido</Typography>

      <Typography className={`${Styles.tableHeaderCell} ${Styles.tableColumn2}`}>Usuário</Typography>

      <Typography className={`${Styles.tableHeaderCell} ${Styles.tableColumn3}`}>Valor</Typography>

      <Typography className={`${Styles.tableHeaderCell} ${Styles.tableColumn4}`}>Data</Typography>

      <Typography className={`${Styles.tableHeaderCell} ${Styles.tableColumn5}`}>Solicitado</Typography>

      <Typography className={`${Styles.tableHeaderCell} ${Styles.tableColumn6}`}>Entregue</Typography>

      <div className={Styles.tableColumn7} />
    </div>
  ) : (
    <div className={Styles.tableRow}>
      <Typography className={`${Styles.tableCell} ${Styles.tableColumn1}`}>{data?._id}</Typography>

      <Typography noWrap className={`${Styles.tableCell} ${Styles.tableColumn2}`}>{data && getClientNameForOrder(data)}</Typography>

      <Typography className={`${Styles.tableCell} ${Styles.tableColumn3}`}>{data?.totalAmount && formatCurrency(data.totalAmount / 100)}</Typography>

      <Typography className={`${Styles.tableCell} ${Styles.tableColumn4}`}>{data?.createdAt && format(new Date(data.createdAt), 'dd/MM/yyyy')}</Typography>

      <Typography className={`${Styles.tableCell} ${Styles.tableColumn5}`}>{data?.createdAt && format(new Date(data.createdAt), 'HH:mm')}</Typography>

      <Typography className={`${Styles.tableCell} ${Styles.tableColumn6}`}>{data?.deliveredAt ? format(new Date(data.deliveredAt), 'HH:mm') : '-'}</Typography>

      <div
        className={Styles.tableColumn7}
        onClick={selectOrder}
      >
        <EditOutlined />
      </div>
    </div>
  );
};
