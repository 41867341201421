import { Container } from "@material-ui/core";
import React, { ReactNode } from "react";

import useStyles from "./styles";

export interface PublicLayoutInterface {
  children: ReactNode;
}

export const PublicLayout: React.FC<PublicLayoutInterface> = ({ children }) => {
  const Styles = useStyles();

  return (
    <Container className={Styles.container} maxWidth={false}>
      <Container className={Styles.contentView} maxWidth={false}>
        <div className={Styles.background} />

        <div className={Styles.content}>{children}</div>
      </Container>
    </Container>
  );
};
