import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles(() => ({

  input: {
    width: 100,
    height: 45,
  },

}));
