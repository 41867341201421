import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  modalView: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: 345,
    height: 320,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 18,
    paddingTop: 52,
    paddingBottom: 60,
  },

  title: {
    fontSize: 18,
    fontWeight: "bold",
  },

  message: {
    fontSize: 16,
    width: 145,
    textAlign: "center",
    marginTop: 15,
  },

  actions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: 110,
    marginTop: 30,
  },
}));

export default useStyles;
