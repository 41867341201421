import { makeStyles } from "@material-ui/core/styles";

const appBarHeight = 76;

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: 0,
    paddingTop: appBarHeight,
    overflowY: "hidden",
  },

  warpper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    padding: 0,
    paddingTop: 10,
    overflowY: "hidden",
  },

  filterSector: {
    marginTop: 40,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
  },

  line: {
    borderTop: 0.2,
    width: '100%',
    borderColor: '#C1C1C5'
  },

  placeHolder: {
    color: '#000000',
    fontSize: 12,
  },

  label: {
    color: '#fff',
    marginBottom: 5,
    fontSize: 14,
    fontWeight: 'bold',
  },
  filterWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 20,
    marginTop: 40,
    gap: 10,
    width: '100%',
    marginBottom: 20,
    alignItems: "center"
  },
}));

export default useStyles;
