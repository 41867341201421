import api from '../configs/api';

import { Client, Clients } from '../types';

export const getAllClientsByEntity = async (entity: string): Promise<Clients> => {
  const {
    status,
    data,
  } = await api.get(`/clients/entity/${entity}`);
  if (status !== 200) throw new Error();
  return data as Clients;
};

export const getClientData = async (admin: string) => {
  const {
    status,
    data,
  } = await api.get(`/clients/admin/${admin}`);
  if (status !== 200) throw new Error();
  return data as Client;
};
