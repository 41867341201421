import { CircularProgress, Typography } from "@material-ui/core";
import React, {
  useEffect,
  useState,
} from "react";
import QRCode from "react-qr-code";
import {
  Button, LoadingModal,
} from "../../components";
import { useStore } from "../../store";
import useStyles from "./styles";
import { useCreatePaymentVisitor, useGetTransaction } from "../../api/payment/hooks";
import { CreatePaymentVisitorResponse } from "../../types";

export const VisitorConfirmPurchase: React.FC = () => {
  const Styles = useStyles();
  const {
    userManager,
    navigation,
    order
  } = useStore();
  const [pixCode, setPixCode] = useState<string>("");
  const [transactionId, setTransactionId] = useState<string>('0');
  const [modalMessage, setModalMessage] = useState<string>("");
  const [seconds, setSeconds] = useState(300);
  const [isActive, setIsActive] = useState(false);
  const { data: transaction, refetch: refetchTransaction } = useGetTransaction(
    transactionId,
    !!transactionId,
  );
  const [reTryPaymentCall, setReTryPaymentCall] = useState(false);
  const { mutateAsync, isLoading } = useCreatePaymentVisitor();
  const formatTime = () => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    return `${minutes}:${secondsLeft < 10 ? '0' : ''}${secondsLeft}`;
  };

  const resetCountdown = (interval: any) => {
    clearInterval(interval);
    setReTryPaymentCall(!reTryPaymentCall);
  };

  const stopTimer = () => {
    setIsActive(false);
  };

  let intervalId: any = null;

  useEffect(() => {
    const makeApiCall = async () => {
      if (transactionId) {
        if (isActive && seconds !== 0) await refetchTransaction();
      }
    };

    intervalId = setInterval(makeApiCall, 1500);

    return () => clearInterval(intervalId);
  }, [transactionId]);

  useEffect(() => {
    (async () => {
      if (transaction) {
        if (transaction.status === '3') {
          setIsActive(false);

          order.reset();

          setModalMessage("Compra Finalizada");

          setTimeout(() => {
            navigation.navigateTo('/login-user');

            setModalMessage("");
          }, 3000);
        }
      }
    })();
  }, [transaction]);

  useEffect(() => {
    (async () => {
      const response: CreatePaymentVisitorResponse = await mutateAsync({
        amount: order.totalAmount,
        entityId: userManager.entity,
        visitorName: order.clientName ?? 'Totem',
        paymentMethod: "6",
        isSelfCheckoutTotem: true,
        isOnCredit: order.isOnCredit || false,
        clientName: order.clientName ? order.clientName.trim() || '' : order.clientName || '',
        items: order.items.map((item) => {
          const {
            id,
            name,
            amount,
            price,
            totalAmount,
          } = item;

          return {
            id,
            name,
            quantity: amount,
            price,
            totalAmount,
          };
        }),
      });

      setTransactionId(response?.id);
      setPixCode(response?.qrCodeKey);
      setIsActive(true);
      setSeconds(300);
    })();
  }, [reTryPaymentCall]);

  useEffect(() => {
    let interval: any = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((s: number) => s - 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  useEffect(() => {
    (async () => {
      if (transaction) {
        if (transaction.status === '3') {
          clearInterval(intervalId);
          setPixCode('');
          stopTimer();
        }
        if (transaction.status !== '3' && seconds === 0) {
          clearInterval(intervalId);
          setPixCode('');
          stopTimer();
        }
      }
    })();
  }, [transaction, seconds]);

  const handleBack = () => {
    navigation.navigateTo("/confirm-manual-purchase", {
      state: {
        type: 'visitor',
      }
    });
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.containerRelative}>
        <div className={Styles.formView}>
          <Typography className={Styles.title}>
            {`Pagamento Pix - ${order.clientName}`}
          </Typography>

          <div className={Styles.PixContainer}>
            {
                isLoading ? (
                  <CircularProgress />
                ) : pixCode && (
                  <QRCode value={pixCode} size={350} />
                )
              }
          </div>

          <div className={Styles.actions}>
            <Typography className={Styles.subTitle}>
              {`Gerar Novamente em ${formatTime()}`}
            </Typography>
            <Button
              size="large"
              fullWidth
              disabled={seconds !== 0}
              onClick={resetCountdown}
            >
              Gerar Novamente
            </Button>
            <div className={Styles.spacer18} />

            <Button
              color="secondary"
              size="large"
              fullWidth
              onClick={() => handleBack()}
            >
              Voltar
            </Button>

          </div>
        </div>

        <LoadingModal
          open={!!modalMessage}
          message={modalMessage}
        />

      </div>
    </div>
  );
};
