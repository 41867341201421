import { makeStyles } from "@material-ui/core/styles";

interface DynamicStylesProps {
  selected: boolean;
}

const useStyles = makeStyles(() => ({
  sectorContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: ({ selected }: DynamicStylesProps) => selected ? "#000" : "#fff",
    minWidth: 130,
    height: 36,
    borderRadius: 12,
  },
  name: {
    fontSize: 14,
    fontWeight: "normal",
    color: ({ selected }: DynamicStylesProps) => selected ? "#fff" : "#000",
  },
}));

export default useStyles;
