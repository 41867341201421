import { useGetEntity } from "../api/orders/hooks";
import { useStore } from "../store";

export const useEntity = () => {
  const { userManager } = useStore();
  const { data: entity, } = useGetEntity(userManager.entity, !!userManager.entity);

  const showSectors = entity?.isSectorsOnThePurchaseTotem;

  return { entity, showSectors };
};
