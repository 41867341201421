import { makeStyles } from "@material-ui/core/styles";

const marginTopList = 22;
const fontSizeTitle = 18;

const useStyles = makeStyles(() => ({
  orderList: {
    overflowY: "hidden",
    width: "50%",
    height: "100%",
  },

  title: {
    fontSize: fontSizeTitle,
    fontWeight: "bold",
    color: "#FFF",
  },

  list: {
    maxHeight: `calc(100% - ${marginTopList + fontSizeTitle}px)`,
    overflowY: "auto",
    marginTop: marginTopList,
    paddingBottom: 15,
    paddingRight: 28,
  },
}));

export default useStyles;
