import React from 'react';
import { AutoComplete } from 'antd';
import { SearchableInputTypes } from '../../types';
import { styles } from './styles';

export const SearchableInput = ({
  onSelect,
  data,
  placeholder,
  value,
  label,
  border,
  onChange,
  onClick,
  onBlur,
}: SearchableInputTypes) => {
  const Styles = styles({ selected: false, disabled: false });
  return (
    <>
      <div>
        <div className={Styles.textLabel}>{label}</div>
        <div className={Styles.inputWarpper}>
          <AutoComplete
            size="large"
            bordered={border}
            style={{
              width: 278,
            }}
            onBlur={onBlur}
            onClick={onClick}
            options={data}
            placeholder={placeholder}
            filterOption={(inputValue, option) => option?.label.toLowerCase()
              .indexOf(inputValue.toLowerCase()) !== -1}
            onSelect={(val, option) => onSelect && onSelect(option)}
            value={value}
            onChange={(inputValue) => onChange(inputValue)}
          />
        </div>
      </div>
    </>
  );
};
