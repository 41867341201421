import { makeStyles, Theme } from "@material-ui/core/styles";

interface DynamicStylesProps {
  picture: string;
}

const clientViewWidth = 320;

const useStyles = makeStyles((theme: Theme) => ({
  clientView: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: clientViewWidth,
    paddingTop: 28,
    paddingBottom: 36,
    backgroundColor: "#F5F5F5",
  },

  client: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 30,
    paddingRight: 36,
    paddingLeft: 25,
  },

  clientInfo: {
    width: 190,

    "& span": {
      fontSize: 14,
      fontWeight: "bold",
      marginBottom: 18,
    },
  },

  clientPhoto: {
    cursor: "pointer",
    width: 55,
    height: 55,
    borderRadius: 55,
    backgroundImage: ({ picture }: DynamicStylesProps) => `url(${picture})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundClip: "border-box",
    boxShadow:
      "0px 8px 12px rgba(0, 0, 0, 0.08), 0px 4px 48px rgba(0, 0, 0, 0.08)",
  },

  picture: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 55,
    height: 55,
    borderRadius: 18,
    backgroundColor: theme.palette.secondary.main,

    "& svg": {
      width: 25,
      height: 25,
    },
  },

  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: 105,
    paddingRight: 36,
    paddingLeft: 25,
  },

  button: {
    width: "100% !important",
  },

  submenuButton: {
    padding: 10,
    width: 130,
  },

  submenuText: {
    cursor: "pointer",
    "& span": {
      fontSize: 14,
      fontWeight: "bold",
      marginBottom: 18,
    },

  },
}));

export default useStyles;
