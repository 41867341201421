import api from '../configs/api';

import {
  CategoryArray
} from '../types';

export const getCategories = async (entity: string): Promise<CategoryArray> => {
  const {
    status,
    data,
  } = await api.get(`/products/categories/entity/${entity}/active`);
  if (status !== 200) throw new Error();
  return data as CategoryArray;
};
